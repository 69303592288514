import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Login from './Login'

const AuthRouter = () => {
  return (
    <Routes>
        <Route index element={<Login/>}/>
        <Route path="login" element={<Login/>}/>
    </Routes>
  )
}

export default AuthRouter

let saveToken = (token)=>{
    localStorage.setItem('token',token)
}

let logout = () =>{
    localStorage.removeItem('token')
    localStorage.removeItem('firstName')
    localStorage.removeItem('lastName')
    localStorage.removeItem('email')
    
}

let saveUserInfo = (firstName, lastName,email) => {
    localStorage.setItem('firstName',firstName)
    localStorage.setItem("lastName", lastName)
    localStorage.setItem("email",email)
}
let isLogged = () =>{
    let token = localStorage.getItem('token')
    return !!token 
}
let getToken = () =>{
    return localStorage.getItem('token')
}

export const accountService = {
     saveToken, logout , isLogged ,getToken,saveUserInfo
}
import React from 'react'
import InformationPerso from './InformationPerso'
import Stepper from './Stepper'
import StepperControl from './StepperControl'
import Cordonnees from './Cordonnees'
import Identifiants from './Identifiants'
import { useState } from 'react'
import { StepperContext } from './contexts/StepperContext'
import { Link } from 'react-router-dom'

const InscriptionComplet = () => {

  const [currentStep, setCurrentStep] = useState(1) 
  const [userData, setUserData] = useState({
        firstName: '',
        lastName:  '',
        phone:  '',
        email: '',
        password: '',
        userProfil: 'online',
        pays :'',
        CNI :'',
        address: '',
        profession:''
  })
  const [pays, setPays] = useState('')
  
  const steps = [
    "Informations Personnelles",
    "Coordonnées",
    "Identifiants"
  ]

  const displayStep = (step) => {
    switch (step) {
      case 1: 
        return <InformationPerso />
      case 2: 
        return <Cordonnees />
      case 3: 
        return <Identifiants />
      default:
    }
  }
  const handleClick = (direction) => {
    let newStep = currentStep
    direction === "next" ? newStep++ : newStep--
    
    newStep > 0 && newStep <= steps.length && setCurrentStep(newStep)
    
  }
  return (
    <>
      <div className="relative h-screen w-full bg-[url('images/bckgroundInscription.jpg')] bg-no-repeat bg-center bg-fixed bg-cover">
        <div className='mt-5'>
            <div className='md:w-96  mx-auto  rounded-2xl pb-2 bg-white' >
                <div className='container horizontal mt-3 '>
                    <Stepper
                      steps={steps}
                      currentStep={currentStep}
                />
    
                {/* Display component */}
                <div className='my-5 p-5'> 
                  <StepperContext.Provider value={{
                    userData, setUserData,
                    pays,setPays
                  }}>
                    {displayStep(currentStep)}
                  </StepperContext.Provider>
                </div>
          </div>
          <StepperControl
                handleClick={handleClick}
                currentStep={currentStep}
                steps={steps}
            />
          {/* {currentStep !== steps.length &&
            
          } */}
            <div className="flex justify-center items-center mt-3">
                <div  className="inline-flex items-center text-gray-700 font-medium text-xs text-center">
                <span className="ml-2">Avez-vous déja un compte?
                    <Link to="/auth/login" className="text-xs ml-2 text-blue-500 font-semibold">Connecter ici</Link>
                </span>
                </div>
            </div>
        </div>
        </div>
    </div>
    </>
  )
}

export default InscriptionComplet
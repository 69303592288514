import { useState, useEffect } from 'react';
import axios from 'axios';

const useVisitorCountry = () => {
  const [visitorCountry, setVisitorCountry] = useState('');

  useEffect(() => {
    axios.get('https://ipapi.co/json/')
      .then(response => {
        setVisitorCountry(response.data.country_name);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  return visitorCountry;
};

export default useVisitorCountry;

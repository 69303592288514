import React from 'react'

const Cards = () => {
  return (
    <>
        <div className="p-5 pt-8 border ignore border-gray-200 not-prose dark:border-gray-800 relative  dark:bg-gray-800">
                {/* <div
                    className="absolute w-auto rounded-b-lg border-b uppercase -translate-y-px tracking-wide leading-none border-l border-r border-gray-200 dark:border-gray-800 shadow-sm top-0 left-1/2 -translate-x-1/2 px-3 pt-1 pb-2 bg-white dark:bg-black text-gray-400 text-[0.65rem]">
                    </div> */}
            <div className="max-w-5xl mx-auto">
                <div className="max-w-7xl mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3  gap-3 sm:gap-5 not-prose">
                    <div
                        className="relative shadow-lg flex flex-col items-start justify-between p-6  rounded-xl border border-gray-200 dark:border-gray-800 dark:bg-black bg-white group">
                        <div className="flex items-center justify-between w-full mb-4 ">
                            <img className='ml-28' src="images/cart.png" alt="cart" />         
                        </div>
                        <span className="relative text-[#0A3F65] font-robo ml-6 dark:text-gray-400 text-center">Achat téléphone neuf à crédit.</span>
                    </div>
                    <div
                        className="relative shadow-lg flex flex-col items-start justify-between p-6  rounded-xl border border-gray-200 dark:border-gray-800 dark:bg-black bg-white group">
                        <div className="flex items-center justify-between w-full mb-4 ">
                            <img className='ml-28' src="images/troc.png" alt="cart" />         
                        </div>
                        <span className="relative text-[#0A3F65] font-robo ml-6 dark:text-gray-400 text-center">Troc ancienne gamme contre nouvelle.</span>
                    </div> 
                    <div
                        className="relative shadow-lg flex flex-col items-start justify-between p-6  rounded-xl border border-gray-200 dark:border-gray-800 dark:bg-black bg-white group">
                        <div className="flex items-center justify-between w-full mb-4 ">
                            <img className='ml-28' src="images/vente.png" alt="cart" />         
                        </div>
                        <span className="relative text-[#0A3F65] font-robo ml-6 dark:text-gray-400 text-center">Achat téléphone reconditionné.</span>
                    </div>              
                </div>
            </div>
        </div>  
    </>
  )
}

export default Cards
import React from 'react'
import { Link } from 'react-router-dom'
import Footer from './Footer'
import Carousel from './Carousel'
import Fade from 'react-reveal/Fade';

const slides = [
    'images/A14-site-.png',
    'images/A54-site..png',
  'images/S23-site-.png',
    'images/S23+-site-.png'
  ]
const Accueil = () => {
  

  return (
    <>
      <div className="relative h-full w-full bg-[url('images/bg-accueil.png')] bg-no-repeat bg-center bg-fixed bg-cover"  >
      <div className="p-2">
          <div className="md:flex md:flex-row mt-10 justify-around" >
            <Fade left>
              <div className="md:w-2/5  flex flex-col justify-center items-center ">
                <h2 className="font-serif text-5xl text-[#0A3F65] mb-4 text-center md:self-start md:text-left font-robo font-bold">IZICALL</h2>
                    <p className="text-[#0A3F65] tracking-wide text-center md:self-start md:text-left font-robo">
                      IZICALL a pour mission d’offrir à ses clients la possibilité d’échanger leur téléphone  contre un vaste choix de téléphone de la marque Samsung allant de la gamme A à la gamme S.  Nous précisons que le règlement du troc se fait à crédit sur une durée de 3 mois. Ce paiement échelonné permet au client de payer de manière souple et adapté à son porte-monnaie. Nous vous proposons les dernières sorties Samsung Galaxy et donnons la priorité à vos préférences.
                </p>
                <Link to="/client/inscription" className="bg-[#e88e56] rounded-xl py-4 px-8 text-gray-50 text-xl md:self-start my-5">S'inscrire</Link>
              </div>
            </Fade>
            
            <Fade right>

            <div className='max-w-lg '>
               <Carousel autoSlide={true} autoSlideInterval={5000}>
              {slides.map((s) => (
               <img src={s} />
            ))}
            </Carousel>
           </div>
            </Fade>
      </div>
       <div className="bg-gradient-to-r from-[#ff914d] via-orange-200 to-transparent bg-no-repeat bg-center bg-fixed bg-cover max-w-full mx-auto grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2  gap-2 sm:gap-4 not-prose">
            <div className="p-5">
              <h3 className="text-2xl font-extrabold mb-4 text-black ml-5">Comment s’inscrire ?</h3>
              <ul>
                <li className='mb-3 leading-relaxed text-black text-xl ml-5'>
                  L’échange de téléphone se fait dans votre espace personnel. Pour cela, vous devez vous inscrire pour pouvoir se connecter à notre plateforme IZICALL et vous souscrire à l’offre.
                </li>
                <li className='mb-3 leading-relaxed text-black font-bold ml-5'>
                  Remplissez les informations personnelles à savoir votre nom, prénom, pays et profession
                </li>
                <li className='mb-3 leading-relaxed text-black font-bold ml-5'>
                    Cliquez sur l’onglet « s’inscrire »
                </li>
                <li className='mb-3 leading-relaxed text-black font-bold ml-5'>
                    Renseignez vos coordonnées à savoir votre adresse, numéro de téléphone, e-mail et CNI
                </li>
                <li className='mb-3 leading-relaxed text-black font-bold ml-5'>
                  Veuillez-vous identifier en créant un nouveau mot de passe
                </li>
                <li className='mb-3 leading-relaxed text-black font-bold ml-5'>
                  Cliquer sur « enregistrer » pour accéder à l’espace personnel
                </li>
              </ul>
      
            </div>
            <div className="lg:flex lg:flex-row ">
             <img src="images/image-troc.png" className='md:w-4/5 md:h-full'  alt="image" /> 
           </div>
      </div>
      </div>
      <Footer/>
    </div>
    </>
  )
}

export default Accueil
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom'
import { clientService } from '../../services/client/client';
import { cryptoService } from '../../services/crypto.service';
import Loading from '../../utils/Loading';
import useVisitorCountry from './useVisitorCountry ';

const Footer = () => {

    
  const [afficheMap,setAfficheMap] = useState(false)
  const visitorCountry = useVisitorCountry();

  useEffect(() => {
    if (visitorCountry === "Senegal") {
        
    }
    else if (visitorCountry === "Mali") {
    }
    else if (visitorCountry === "Côte d'Ivoire") {
      
    }
    else {
      
    }
      
  }, [])
    
    const [news, setNews] = useState({
        firstName: '',
        lastName: '',
        email : ''
  })

    const onChangeNews = (e)=>{
        setNews({
            ...news,
            [e.target.name]: e.target.value
        })
    }
    const [isloading, setIsloading] = useState(false)
    const [success, setSuccess] = useState(false)
    
    const handleNewletter = (e) => {
        e.preventDefault()
        setIsloading(true)

        const newLetter = cryptoService.encrypt(news,process.env.REACT_APP_SECRET_KEY)
        clientService.sendNewsletter(newLetter)
            .then(response => {
                if (response.ok) {
                    setIsloading(false)
                    setSuccess(true)
                    setNews({
                        firstName: '',
                        lastName: '',
                        email : ''
                     })
                }
                else {
                    setIsloading(false)
                }
        })
        
    }
  return (
    <>
      <section className=" py-12 text-gray-800 sm:py-24">
          <div className="mx-auto flex justify-around max-w-md flex-col rounded-lg lg:max-w-screen-xl lg:flex-row">
              <div className="border   border-gray-100 shadow-gray-500/20 mt-8 mb-8 max-w-md bg-[#0A3F65] shadow-sm sm:rounded-lg sm:shadow-lg lg:mt-0">
                  <div className="relative border-b border-gray-300 p-4 py-8 sm:px-8">
                      <h3 className="mb-1 text-center text-xl font-medium"><span className="mr-4 uppercase text-white">Recevez nos offres par email</span></h3>
                  </div>
                      <form onSubmit={handleNewletter}>
                          {isloading && <Loading />}
                          {success && <div className="flex  justify-between bg-teal-100 border border-teal-400 text-teal-700 px-4 py-3 my-2 rounded  "
                                    role="alert">
                                    <span className="block sm:inline pl-2">
                                        <strong className="font-bold">Super!</strong>
                                        La requête a été bien prise en compte
                                    </span>
                                    <span onClick={()=>setSuccess(false)} className="inline" >
                                        <svg className="fill-current h-6 w-6" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                            <title>Close</title>
                                            <path
                                                d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                                        </svg>
                                    </span>
                                </div>
                          }
                        <div className="p-4 sm:p-8">
                            <input value={news.firstName} onChange={onChangeNews} id="firstName" type="text" name='firstName' className="mt-4 w-full resize-y overflow-auto rounded-lg border border-gray-300 px-4 py-2 shadow-sm focus:border-blue-500 focus:outline-none hover:border-blue-500" placeholder="Votre prénom" />
                            <input value={news.lastName} onChange={onChangeNews} id="lastName" type="text" name='lastName' className="mt-4 w-full resize-y overflow-auto rounded-lg border border-gray-300 px-4 py-2 shadow-sm focus:border-blue-500 focus:outline-none hover:border-blue-500" placeholder="Votre nom" />
                            <input value={news.email} onChange={onChangeNews} id="email" type="email" name='email' required className="peer mt-8 w-full resize-y overflow-auto rounded-lg border border-gray-300 px-4 py-2 shadow-sm focus:border-blue-500 focus:outline-none hover:border-blue-500" placeholder="Votre email" />
                            <button type='submit'  className="w-full mt-3 rounded-lg border border-white  p-3 text-center font-medium text-white outline-none transition focus:ring   hover:text-white uppercase">Soumettre</button>
                       </div>
                  </form>
              </div> 
          <iframe className='h-70 rounded-xl w-full ml-3'
            src='https://maps.google.com/maps?q=18.081594,-15.976268&hl=es;&output=embed' title='localistaion' ></iframe>   
        </div>
    </section>

      <div className="text-center lg:text-left bg-gray-100 text-gray-600">
      {/* <iframe src='https://maps.google.com/maps?q=18.081594,-15.976268&hl=es;&output=embed' title='localistaion' height="280px" width="100%"></iframe> */}
        <footer className="bg-gray-800">
            <div className="mx-auto max-w-7xl py-8 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
                <nav className="-mx-5 -my-2 flex flex-wrap justify-center order-2" aria-label="Footer">
                    <div className='flex flex-col m-6'>
                            <p className='text-white font-bold mb-1'>Contact</p>
                            <p className='text-white font-bold mb-1'>Dakar,Mermoz, Sénégal</p>
                            <p className='text-white font-bold mb-1'>infolog@digital.sn</p>
                            <p className='text-white font-bold mb-1'>88 23456788/8823456789</p>
                        </div>
                </nav>
                <div className="mt-8 md:mb-8 flex justify-center space-x-6 md:order-3  ">
                    <a href="https://www.facebook.com/izishopsn" target="_blank" className="text-white hover:text-gray-200">
                        <span className="sr-only">Facebook</span>
                        <svg className="h-8 w-8" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                            <path fillRule="evenodd"
                                d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                                clipRule="evenodd" />
                        </svg>
                    </a>

                    <a href="https://twitter.com/izishopsn" target="_blank" className="text-white hover:text-gray-200">
                        <span className="sr-only">Twitter</span>
                        <svg className="h-8 w-8" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                            <path
                                d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                        </svg>
                          </a>
                    <a href="https://www.instagram.com/izishopsn/" target="_blank" className="text-white hover:text-gray-200 dark:hover:text-white">
                        <svg className="w-8 h-8" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path fill-rule="evenodd" d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z" clip-rule="evenodd" /></svg>
                        <span className="sr-only">Instagram page</span>
                          </a> 
                    <a href="https://www.linkedin.com/company/national-cash-sn/about/?viewAsMember=true"  target="_blank" className="mr-6 text-white hover:text-gray-200">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-8 w-8"
                            fill="currentColor"
                            viewBox="0 0 24 24">
                            <path
                                d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z" />
                        </svg>
                    </a>
                </div>
                <div className="mt-8  ">
                    <img className='w-24 h-24' src="images/logo.png" alt="image" />
                </div>
             </div>
           </footer>
    </div>
    </>
  )
}

export default Footer
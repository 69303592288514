import React from 'react'

const Home = () => {

  return (
    <>
      <div className="relative h-screen w-full bg-white bg-no-repeat bg-center bg-fixed bg-cover" > 
        <p className='font-bold mt-5 ml-10 '>Bienvenue dans votre espace personnel !</p>
        <div className="rounded-2xl  bg-gradient-to-r  from-[#ff914d] via-orange-200 to-transparent bg-no-repeat bg-center bg-fixed bg-cover max-w-full mx-auto grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2  gap-2 sm:gap-4 not-prose">
            <div className="p-10">
                <h3 className="text-2xl font-extrabold mb-4 text-black ml-5">Comment troquer votre téléphone?</h3>
                <p className="mb-3 leading-relaxed text-black text-xl ml-5">Pour échanger votre téléphone c'est simple !.</p>
                <p className="mb-3 leading-relaxed text-black text-xl ml-5">Il suffit de suivre ces différentes étapes:</p>
                <ul>
                    <li className='mb-3 leading-relaxed text-black font-bold ml-5'>Cliquez sur l'onglet « IZICALL»</li>
                    <li className='mb-3 leading-relaxed text-black font-bold ml-5'>
                        Remplissez le formulaire en donnant les informations de votre téléphone et son état matériel
                    </li>
                    <li className='mb-3 leading-relaxed text-black font-bold ml-5'>
                        Cliquez sur le bouton envoyer et une estimation de l'échange vous sera communiqué.
                    </li>
                </ul>
                {/* <p className="mb-3 leading-relaxed text-black font-bold ml-5">-&nbsp;&nbsp;&nbsp;&nbsp;Cliquez sur l'onglet « IZICALL»</p>
                <p className="mb-3 leading-relaxed text-black font-bold ml-5">-&nbsp;&nbsp;&nbsp;&nbsp;Remplissez le formulaire en donnant les informations de votre téléphone et son état matériel</p>
                <p className="mb-3 leading-relaxed text-black font-bold ml-5">-&nbsp;&nbsp;&nbsp;&nbsp;Cliquez sur le bouton envoyer et une estimation de l'échange vous sera communiqué.</p> */}
            </div>
            <div className="lg:flex lg:flex-row ">
             <img src="/images/image-explication-form.png" className='md:w-1/3 md:ml-32 md:h-full'  alt="image" /> 
           </div>
        </div>
        
        <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-3  gap-2 sm:gap-3 m-5  ">
          
            <img src="/images/image3.png" className='w-96 h-96 ' alt="image" />
            <img src="/images/image2.png" className='w-96 h-96' alt="image" />
            <img src="/images/image1.png" className='w-96 h-96' alt="image" />
          
        </div>

        <div className="flex flex-wrap items-center mt-20 text-center">
        <div className="md:w-3/5 lg:w-1/2 px-4 ml-10">
            <img src="/images/echange.png"  alt="gem" className="inline-block w-96 h-full"/>
        </div>
        <div className="w-full md:w-2/5 px-4 md:text-left ">
            <h3 className="font-bold mt-8 text-xl md:mt-0 sm:text-2xl">
                Avec IZICAL, changez facilement de téléphone !
            </h3>
            <p className="sm:text-lg mt-6">
                L’offre vous permet de troquer votre Samsung Galaxy actuel contre une nouvelle gamme Galaxy et le tout pour un paiement échelonné sur 3 mois, IZIFÄY pour faciliter le paiement de votre nouveau téléphone.
              Pour troquer votre téléphone, veuillez remplir les différentes informations et rendez-vous à notre boutique Samsung IZISHOP, sise à Mermoz VDN pour récupérer votre nouveau Sam-sung Galaxy .
            </p>
        </div>
        </div>
        <p className="mb-3 leading-relaxed text-black text-xl  md:ml-24 md:mr-24 mt-2">
          Services après ventes
          A IZISHOP nous disposons d'un service après-vente pour nos téléphones. Ce SAV offre plusieurs avantages: 
          -Lorsque votre écran est cassé IZICALL vous donne la possibilité de profiter d’une garantie cassure écran et d’une garantie Samsung care en cas de dommage
          -Pour la réparation sous garanties, c’est-à-dire si le téléphone est endommagé pendant la période de garantie, nous nous engagerons à le réparer gratuitement ou à le remplacer si nécessaire.
          -Nous disposons d'un service client compétent et réactif qui peut vous aider à résoudre vos problèmes, répondre à vos questions et vous fournir des conseils techniques.
        </p>
        <footer className="bg-gray-800">
            <div className="mx-auto max-w-7xl py-8 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
                <nav className="-mx-5 -my-2 flex flex-wrap justify-center order-2" aria-label="Footer">
                    <div className='flex flex-col m-6'>
                            <p className='text-white font-bold mb-1'>Contact</p>
                            <p className='text-white font-bold mb-1'>Dakar,Mermoz, Sénégal</p>
                            <p className='text-white font-bold mb-1'>infolog@digital.sn</p>
                            <p className='text-white font-bold mb-1'>88 23456788/8823456789</p>
                        </div>
                </nav>
                <div className="mt-8 md:mb-8 flex justify-center space-x-6 md:order-3  ">
                    <a href="https://www.facebook.com/boutiquesamsungsn/" className="text-white hover:text-gray-200">
                        <span className="sr-only">Facebook</span>
                        <svg className="h-8 w-8" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                            <path fillRule="evenodd"
                                d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                                clipRule="evenodd" />
                        </svg>
                    </a>

                    <a href="https://www.instagram.com/izishopsn/?next=%2Finfologmauritania%2F" className="text-white hover:text-gray-200">
                        <span className="sr-only">Twitter</span>
                        <svg className="h-8 w-8" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                            <path
                                d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                        </svg>
                    </a>

                    <a href="#" className="text-white hover:text-gray-200">
                        <span className="sr-only">GitHub</span>
                        <svg className="w-8 h-8" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                            <path fillRule="evenodd"
                                d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                                clipRule="evenodd"></path>
                        </svg>
                    </a>
                </div>
                <div className="mt-8  ">
                    <img className='w-24 h-24' src="/images/logo.png" alt="" />
                </div>
             </div>
           </footer>
      </div>
      
    </>
  )
}

export default Home
import React from 'react'
import { Link } from 'react-router-dom'

const StepperControl = ({ handleClick, currentStep, steps }) => {
  
  return (
    <div> 
      <div className='container flex justify-around mt-4 mb-8'>
        <Link to="/"  
              className="bg-black text-slate-400 uppercase py-2 px-4
                rounded-xl font-semibold cursor-pointer border-2 border-slate-300
                hover:bg-red-700 hover:text-white transition duration-200 ease-in-out">
             Quitter 
          </Link>
          {/* back button */}
          <button
              onClick={()=>handleClick()}
              className={`bg-white text-slate-400 uppercase py-2 px-4
                rounded-xl font-semibold cursor-pointer border-2 border-slate-300
                hover:bg-slate-700 hover:text-white transition duration-200 ease-in-out
                ${currentStep === 1 ? "opacity-50 cursor-not-allowed" : ""}
                `} >
             Retour 
          </button>
          {/* next button */}
      {currentStep !== steps.length &&
        <button
        onClick={() => { handleClick("next")}}
              className='bg-green-500 text-white uppercase py-2 px-4
                rounded-xl font-semibold cursor-pointer  hover:bg-slate-700
              hover:text-white transition duration-200 ease-in-out
          ' >
          {/* {currentStep === steps.length  ? "Terminer" : "Suivant"}   */}
          Suivant
        </button>
      }
    </div>
    </div>
  )
}

export default StepperControl
import React, { useState } from 'react'
import { clientService } from '../../services/client/client'
import { cryptoService } from '../../services/crypto.service'

const ResetPassword = () => {
  const [email, setEmail] = useState('')
  const [isloading, setIsloading] = useState(false)
  const [successMessage, setSuccesMessage] = useState(false)
  const [errorMessage,setErrorMessage] = useState(false)
  const handleForm = (e) => {
    
    e.preventDefault()
    setIsloading(true)
    const mail = cryptoService.encrypt(email,process.env.REACT_APP_SECRET_KEY)
    clientService.resetPassWord(mail)
      .then((response) => {
        if (response.status === 200) {
          setIsloading(false)
          setSuccesMessage(true) 
        }
        else {
          setIsloading(false)
          setErrorMessage(true)
        }
    })
  }
  return (
    <div className='w-full max-w-xl xl:w-5/12 xl:px-8 mt-10 md:ml-32'>
          <form  onSubmit={handleForm} >
                <div className="mb-1 sm:mb-2">
                    <p className="mb-2 h-12 w-full"></p>
            </div>
              {isloading && <div className="border-gray-300 h-20 w-20 animate-spin rounded-full border-8 border-t-blue-600 ml-16 mb-5" />}
              {successMessage && <div className="bg-green-200 mb-3 border-green-600 text-green-600 border-l-4 p-4" role="alert">
                        <p className="font-bold">
                            Réussie!
                        </p>
                        <p>
                            votre nouveau mot de passe a été envoyé dans votre boite email.
                        </p>
                  </div>
                }
                {
                  errorMessage && <div className="bg-red-200 mb-3 border-red-600 text-red-600 border-l-4 p-4" role="alert">
                        <p className="font-bold">
                            Erreur!
                        </p>
                        <p>
                            Une erreur s'est produite lors du mise à jour de votre mot de passe.
                        </p>
                  </div>
                }
              <div className="mb-1 sm:mb-2">
                <label htmlFor="email" className="mb-1 inline-block font-medium text-emerald-900">Email</label>
                <input  onChange={(e)=>setEmail(e.target.value)}  name="email"   placeholder="exemple@gmail.com" required type="email" className="mb-2 h-12 w-full flex-grow appearance-none rounded border border-gray-300 bg-white px-4 shadow-sm ring-blue-200 transition duration-200 focus:border-blue-400 focus:outline-none focus:ring" id="email"  />
              </div>
                <div className="flex justify-center items-center mt-6"> 
              </div>
              <div className="mt-4 mb-2 sm:mb-4">
                <button type="submit" className="inline-flex h-12 w-full items-center justify-center rounded-xl bg-blue-600 px-6 font-medium tracking-wide text-white shadow-md ring-blue-200 transition duration-200 hover:bg-blue-700 focus:outline-none focus:ring">
                    Soumettre
                </button>
              </div>
              <div className="flex justify-center items-center mt-6">
              
              </div>
            </form>
    </div>
  )
}

export default ResetPassword
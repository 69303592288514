import React from 'react'
import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { phoneServices } from '../../services/client/phone'
import Nav from './Nav'

const Layout = () => {

  
  return (
    <>
        <Nav  />
        <Outlet/>
    </>
  )
}

export default Layout
import React from "react";

function SliderContent({ activeIndex, sliderImage }) {
  return (
    <section>
      {sliderImage.map((slide, index) => (
        <div
          key={index}
          className={index === activeIndex ? "slides active" : "inactive"}
        >
          <div className="mt-14">
              <img className="w-full" style={{height:"450px"}} src={slide.urls}  alt="slide" />
          </div>
        </div>
      ))}
    </section>
  );
}

export default SliderContent;
let createClient =  (client)=>{
    
    return  fetch(`https://${process.env.REACT_APP_ADRESSE_PORT}/signup`,
          {
              method: 'POST',
              headers: { 'Accept': 'application/json','data':client},
          }
          )
  }

  let login =  (credential)=>{
    
    return  fetch(`https://${process.env.REACT_APP_ADRESSE_PORT}/loginOnline`,
          {
            method: 'POST',
            mode: 'cors',
            headers: { 'Accept': 'application/json','data':credential},
          }
          )
  }

  let estimerEchange =  (data)=>{
    
    return  fetch(`https://${process.env.REACT_APP_ADRESSE_PORT}/loginOnline`,
          {
              method: 'POST',
              headers: { 'Accept': 'application/json','data':data},
          }
          )
  }

let getCommandeEncoour = () => {
      
  const options = {
    method: 'GET',
    headers: { 'Accept': 'application/json',  'Authorization': "Bearer "+localStorage.getItem('token')},

    }
  return  fetch(`https://${process.env.REACT_APP_ADRESSE_PORT}/payer`,options)

}

let resetPassWord = (email) => {
      
  const options = {
    
    method: 'POST',
    headers: { 'Accept': 'application/json',  'data':email },

    }
  return  fetch(`https://${process.env.REACT_APP_ADRESSE_PORT}/forgot`,options)

}

let updatePassWord = (password) => {
      
  const options = {
    
    method: 'POST',
    headers: { 'Accept': 'application/json', 'data':password ,'Authorization': "Bearer "+localStorage.getItem('token')},

    }
  return  fetch(`https://${process.env.REACT_APP_ADRESSE_PORT}/updatePasswordOnline`,options)

}
let getAgentForPaiment = () => {
      
  const options = {
    
    method: 'GET',
    headers: { 'Accept': 'application/json' ,'Authorization': "Bearer "+localStorage.getItem('token')},

    }
  return  fetch(`https://${process.env.REACT_APP_ADRESSE_PORT}/shopOnline`,options)

}

let sendNewsletter = (news) => {
      
  const options = {
    
    method: 'POST',
    headers: { 'Accept': 'application/json', 'data': news,'Authorization': "Bearer "+localStorage.getItem('token')},

    }
  return  fetch(`https://${process.env.REACT_APP_ADRESSE_PORT}/news`,options)

}

let getDetailEstimation = (model) => {    
  const options = {
    
    method: 'GET',
    headers: { 'Accept': 'application/json', 'data': model,'Authorization': "Bearer "+localStorage.getItem('token')},

    }
  return  fetch(`https://${process.env.REACT_APP_ADRESSE_PORT}/getDetail`,options)

}
let supprimerEstimation = (id) => {    
  const options = {
    
    method: 'DELETE',
    headers: { 'Accept': 'application/json','Authorization': "Bearer "+localStorage.getItem('token')},

    }
  return  fetch(`https://${process.env.REACT_APP_ADRESSE_PORT}/phoneTroc/${id}`,options)

}

  export const clientService = {
    createClient, login, estimerEchange,getCommandeEncoour,resetPassWord,updatePassWord,getAgentForPaiment,sendNewsletter,getDetailEstimation,supprimerEstimation
}
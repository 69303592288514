
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AuthRouter from './components/Auth/AuthRouter';
import ClientRouter from './components/client/ClientRouter';
import InscriptionComplet from './components/client/inscription/InscriptionComplet';
import ErrorPage from './components/public/ErrorPage';
import PaytechButton from './components/public/PaytechButton';
import PublicRouter from './components/public/PublicRouter';
import AuthGuard from './_helper/AuthGuard'

function App() {
  
  return (
    <Router>
          <Routes>
                <Route  path='/client/compte/*' element={
                  <AuthGuard >
                      <ClientRouter />
                  </AuthGuard>
                }/> 
                <Route  path='/auth/*' element={<AuthRouter />} />
                <Route  path="/client/inscription" element={<InscriptionComplet />}/>
                <Route  path='/*' element={<PublicRouter  />} /> 
                <Route  path='/payment' element={<PaytechButton />} /> 
                <Route  path='*' element={<ErrorPage />} /> 
            </Routes>
      </Router>
  );
}

export default App;

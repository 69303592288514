import { Navigate } from "react-router-dom"

const AuthGuard = ({children}) => {

    if(localStorage.getItem('token') === null ){
        return <Navigate to="/auth/login" />
    }
    return children 
  
}

export default AuthGuard
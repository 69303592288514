import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Commandes from './compte/Commandes'
import FormulaireSiteWeb from './compte/FormulaireSiteWeb'
import Home from './compte/Home'
import ResultatEstim from './compte/ResultatEstim'
import SuiviEstimation from './compte/SuiviEstimation'
import UpdatePassword from './compte/UpdatePassword'
import Layout from './Layout'

const ClientRouter = () => {
  return (
    
    <Routes>
      {/* <Route index element={<Nav />}/> */}
         <Route element={<Layout/>}>
            <Route  path="home" element={<Home/>}/>
            <Route  path="form-estimation" element={<FormulaireSiteWeb/>}/> 
            <Route  path="commandes" element={<Commandes/>}/>
            <Route  path="resultatEstimation" element={<ResultatEstim />} />
            <Route  path="updatePassword" element={<UpdatePassword />} />
            <Route  path="suivi-estimation" element={<SuiviEstimation />} />
         </Route>
    </Routes>
    
  )
}

export default ClientRouter
import React from 'react'
import { useContext } from 'react'
import { StepperContext } from './contexts/StepperContext'

const Cordonnees = () => {
    const { userData, setUserData } = useContext(StepperContext)
    

    const handleChange = (e) => {
        const { name, value } = e.target
        setUserData({...userData, [name] : value})
    }
  return (
      <div className='flex flex-col'>
           <div className='w-full mx-2 flex-1'>
              <div className='font-bold h-6 mt-3 text-gray-500 text-xs leading-8 uppercase'>
                 Adresse <span className='text-red-600'>*</span>
              </div>
              <div className='bg-white my-2 p-1 flex border border-gray-200 rounded'>
                  <input
                      onChange={handleChange}
                      value={userData.address}
                      name="address"
                      placeholder='Votre adresse domicile'
                      className='p-1 px-2 appearance-none outline-none w-full text-gray-800'
                      type="text" />
              </div>
          </div>
          <div className='w-full mx-2 flex-1'>
              <div className='font-bold h-6 mt-3 text-gray-500 text-xs leading-8 uppercase'>
                 Téléphone <span className='text-red-600'>*</span> 
              </div>
              <div className='bg-white my-2 p-1 flex border border-gray-200 rounded'>
                  <input
                      onChange={handleChange}
                      value={userData.phone}
                      name="phone"
                      placeholder='Votre numéro de téléphone'
                      className='p-1 px-2 appearance-none outline-none w-full text-gray-800'
                      type="tel" required pattern="[0-9]*" />
              </div> 
          </div>
          <div className='w-full mx-2 flex-1'>
              <div className='font-bold h-6 mt-3 text-gray-500 text-xs leading-8 uppercase'>
                 Email <span className='text-red-600'>*</span>
              </div>
              <div className='bg-white my-2 p-1 flex border border-gray-200 rounded'>
                  <input
                      onChange={handleChange}
                      value={userData.email}
                      name="email"
                      placeholder='Votre adresse email'
                      className='p-1 px-2 appearance-none outline-none w-full text-gray-800'
                      type="email" />
              </div> 
          </div>
          <div className='w-full mx-2 flex-1'>
              <div className='font-bold h-6 mt-3 text-gray-500 text-xs leading-8 uppercase'>
                 CNI 
              </div>
              <div className='bg-white my-2 p-1 flex border border-gray-200 rounded'>
                  <input
                      onChange={handleChange}
                      value={userData.CNI}
                      name="CNI"
                      placeholder="Votre numéro d'identité national"
                      className='p-1 px-2 appearance-none outline-none w-full text-gray-800'
                      type="text" pattern="[0-9]*" />
              </div>
          </div>
    </div>
  )
}

export default Cordonnees
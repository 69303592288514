
let getAllModel = () => { 
    const options = {
                method: 'GET',
                headers: { 'Accept': 'application/json','Authorization': "Bearer "+localStorage.getItem('token')},

                }
                
    return  fetch(`https://${process.env.REACT_APP_ADRESSE_PORT}/phoneChangeOnline`,options)
  }

  let sendFormulaire =(data)=>{
    const options = {
                method: 'POST',
                headers: { 'Accept': 'application/json', 'data': data ,'Authorization': "Bearer "+localStorage.getItem('token')},

                }
                
    return  fetch(`https://${process.env.REACT_APP_ADRESSE_PORT}/clientOnline`,options)
  }

  let getModelChoise =(model)=>{
    const options = {
                method: 'GET',
                headers: { 'Accept': 'application/json', 'data': model ,'Authorization': "Bearer "+localStorage.getItem('token')},

                }
                
    return  fetch(`https://${process.env.REACT_APP_ADRESSE_PORT}/modelChangeOnline`,options)
  }

 let saveFormulaire = (phone) => {
  const options = {
    method: 'POST',
    headers: { 'Accept': 'application/json','data': phone, 'Authorization': "Bearer "+localStorage.getItem('token')},

    }
  return  fetch(`https://${process.env.REACT_APP_ADRESSE_PORT}/phoneTroc`,options)

}

let updateFormilaire = (data,id) => {
  const options = {
    method: 'PUT',
    headers: { 'Accept': 'application/json', 'data' : data, 'Authorization': "Bearer "+localStorage.getItem('token')},

    }
  return  fetch(`https://${process.env.REACT_APP_ADRESSE_PORT}/updateEstimation/${id}`,options)
}

let CancelModif = (id) => {
  const options = {
    method: 'GET',
    headers: { 'Accept': 'application/json',  'Authorization': "Bearer "+localStorage.getItem('token')},

    }
  return  fetch(`https://${process.env.REACT_APP_ADRESSE_PORT}/phoneTroc/${id}`,options)

}
let checkPossibleCommand = () => {
  const options = {
    method: 'GET',
    headers: { 'Accept': 'application/json',  'Authorization': "Bearer "+localStorage.getItem('token')},

    }
  return  fetch(`https://${process.env.REACT_APP_ADRESSE_PORT}/getPhoneTroc/`,options)

}
let checkEstimation = () => {
  const options = {
    method: 'GET',
    headers: { 'Accept': 'application/json',  'Authorization': "Bearer "+localStorage.getItem('token')},

    }
  return  fetch(`https://${process.env.REACT_APP_ADRESSE_PORT}/getTroc`,options)

}


  export const phoneServices = {
    getAllModel,sendFormulaire,getModelChoise,saveFormulaire, updateFormilaire, CancelModif,checkPossibleCommand,checkEstimation
}
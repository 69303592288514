import React from 'react'
import Television from './Television'

const Televisions = () => {
  return (
    <>
    <div className="h-64 rounded-md overflow-hidden bg-cover bg-center" style={{backgroundImage: "url('https://www.promo.sn/wp-content/uploads/2020/03/55n2-650x650.jpg')"}}>
        <div className="bg-gray-900 bg-opacity-50 flex items-center h-full">
            <div className="px-10 max-w-xl">
                <h2 className="text-2xl text-white font-semibold">Des Télévisisons de Qualités</h2>
                <p className="mt-2 text-gray-400">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Tempore facere provident molestias ipsam sint voluptatum pariatur.</p>
               
            </div>
        </div>
    </div>
       <div className="container mx-auto px-6">
            
            <div className="grid gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mt-6">
                <Television image={"https://i0.wp.com/www.promo.sn/wp-content/uploads/2018/11/nasco55.jpg?w=1024&ssl=1"} prix={"150000"} name={"Télévision"} />
                <Television image={"https://www.promo.sn/wp-content/uploads/2020/03/55n2-650x650.jpg"} prix={"150000"} name={"Télévision"} />
                <Television image={"https://www.promo.sn/wp-content/uploads/2018/07/M5010-1547-1.jpg"} prix={"150000"} name={"Télévision"} />
                <Television image={"https://th.bing.com/th/id/OIP.4ntuHT2rs07AfT33n6cpugHaHa?pid=ImgDet&rs=1"} prix={"150000"} name={"Télévision"} />
                <Television image={"https://guyane.darty-dom.com/thumbnails/product/179/179867/square/900/179867.jpg"} prix={"150000"} name={"Télévision"} />
                <Television image={"https://i0.wp.com/www.promo.sn/wp-content/uploads/2018/11/nasco55.jpg?w=1024&ssl=1"} prix={"150000"} name={"Télévision"} />
                <Television image={"https://www.promo.sn/wp-content/uploads/2020/03/55n2-650x650.jpg"} prix={"150000"} name={"Télévision"} />
                <Television image={"https://th.bing.com/th/id/OIP.4ntuHT2rs07AfT33n6cpugHaHa?pid=ImgDet&rs=1"} prix={"150000"} name={"Télévision"} />
                <Television image={"https://th.bing.com/th/id/OIP.4ntuHT2rs07AfT33n6cpugHaHa?pid=ImgDet&rs=1"} prix={"150000"} name={"Télévision"} /> 
            </div>
        </div>
    </>
  )
}

export default Televisions
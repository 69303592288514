import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Accueil from './Accueil'
import Apropos from './Apropos'
import DetailProduit from './DetailProduit'
import Electromenager from './Electromenager'
import Footer from './Footer'
import LayoutPublic from './LayoutPublic'
import TelevisionDetail from './television/TelevisionDetail'
import Televisions from './television/Televisions'
import ResetPassword from '../Auth/ResetPassword'
import Services from './Services'
const PublicRouter = () => {
  
  return (
    <Routes>
      {/* <Route index element={<Accueil />}/> */}
         <Route element={<LayoutPublic/>}>
            <Route  path="/" element={<Accueil  />}/>
            <Route  path="contact" element={<Footer/>}/>
            <Route  path="detail-produit" element={<DetailProduit/>}/>
            <Route  path="/apropos" element={<Apropos />}/>
            <Route  path="/electromenager" element={<Electromenager  />}/>
            <Route  path="/televisions" element={<Televisions />}/>
            <Route path="/televisionDetail" element={<TelevisionDetail />} />
            <Route  path="reset-password" element={<ResetPassword />}/>
            <Route  path="/services" element={<Services />}/>
         </Route>
    </Routes>
  )
}

export default PublicRouter
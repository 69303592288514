import React from 'react'
import { Link } from 'react-router-dom'

const Electromenager = ({cart, updateCart}) => {
  return (
    <>
        <div className="bg-cover bg-no-repeat bg-center py-36" style={{
                backgroundImage: "url('https://brief.pl/wp-content/uploads/2015/01/Fotolia_71515946_Subscription_Monthly_M.jpg')"
              }}>
            <div className="container">
                <h1 className="text-6xl text-gray-800 font-medium mb-4 capitalize">
                    best collection for <br /> home decoration
                </h1>
                <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aperiam <br/>
                    accusantium perspiciatis, sapiente
                    magni eos dolorum ex quos dolores odio</p>
            </div>
        </div>
        <div className="container py-16">
            <h2 className="text-2xl font-medium text-gray-800 uppercase mb-6">Toutes les catégories</h2>
            <div className="grid grid-cols-3 gap-3">
                <div className="relative rounded-sm overflow-hidden group">
                    <img src="https://th.bing.com/th/id/OIP.4ntuHT2rs07AfT33n6cpugHaHa?pid=ImgDet&rs=1" alt="category 1" className="w-full" />
                    <Link to="/televisions"
                        className="absolute inset-0 bg-black bg-opacity-40 flex items-center justify-center text-xl text-white font-roboto font-medium group-hover:bg-opacity-60 transition">Télévision</Link>
                </div>
                <div className="relative rounded-sm overflow-hidden group">
                    <img src="https://www.meilleur-comparatif.fr/wp-content/uploads/2018/01/r%C3%A9frig%C3%A9rateur.jpg" alt="category 1" className="w-full" />
                    <Link to="#"
                        className="absolute inset-0 bg-black bg-opacity-40 flex items-center justify-center text-xl text-white font-roboto font-medium group-hover:bg-opacity-60 transition">RefriGérateur</Link>
                </div>
                <div className="relative rounded-sm overflow-hidden group">
                    <img src="https://th.bing.com/th/id/OIP.ruvr4YjUZkQsVdvyO-NfNQHaHa?pid=ImgDet&rs=1" alt="category 1" className="w-full" />
                    <Link to="#"
                        className="absolute inset-0 bg-black bg-opacity-40 flex items-center justify-center text-xl text-white font-roboto font-medium group-hover:bg-opacity-60 transition">Microonde
                    </Link>
                </div>
                <div className="relative rounded-sm overflow-hidden group">
                    <img src="https://media.but.fr/images_produits/produit-zoom/3364330008332_Q.jpg" alt="category 1" className="w-full" />
                    <Link to="#"
                        className="absolute inset-0 bg-black bg-opacity-40 flex items-center justify-center text-xl text-white font-roboto font-medium group-hover:bg-opacity-60 transition">Cafétiere</Link>
                </div>
                <div className="relative rounded-sm overflow-hidden group">
                    <img src="https://lh6.googleusercontent.com/proxy/Ew_8rsLvNs-7SSyhVaylHAlp0NipOMj_8a1uvOJENTjpyVbOa7GRiZthLFepeb_IBJA0sPdnFKHIj-m66XigiwIwwEw4lPadrSurq9nxewhiMnwI8WUnJm4amID-yA=w1200-h630-p-k-no-nu" alt="category 1" className="w-full" />
                    <Link to="#"
                        className="absolute inset-0 bg-black bg-opacity-40 flex items-center justify-center text-xl text-white font-roboto font-medium group-hover:bg-opacity-60 transition">Chauffe Eaux
                    </Link>
                </div>
                <div className="relative rounded-sm overflow-hidden group">
                    <img src="https://media.gettyimages.com/vectors/household-appliances-bluegray-icons-vector-id509703601" alt="category 1" className="w-full" />
                    <Link to="#"
                        className="absolute inset-0 bg-black bg-opacity-40 flex items-center justify-center text-xl text-white font-roboto font-medium group-hover:bg-opacity-60 transition">Kitchen</Link>
                </div>
            </div>
        </div>
    </>
  )
}

export default Electromenager
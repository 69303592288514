import React, { useRef } from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { clientService } from '../../../services/client/client'
import { cryptoService } from '../../../services/crypto.service'
import { Progress } from "@material-tailwind/react";
import Loading from '../../../utils/Loading'
import Modal from '../../../utils/Model'

const Commandes = () => {
  
  let navigate = useNavigate()

  const [isloading, setIsloading] = useState(true)
  const [payed, setPayed] = useState([])
  const [next, setNext] = useState([])
  const [pending, setPending] = useState([])
  const [docNum, setDocNum] = useState()
  const [ferme, setFerme] = useState(false)
  const [videCommande,setVideCommande] = useState(false)
  const [nombreMois, setNombreMois] = useState()
  const [afficheModalPayer, setAfficheModalPayer] = useState(false)
  
  const [agences, setAgences] = useState()
  
  const flag = useRef(false)

  useEffect(()=>{
    if(flag.current === false){
      clientService.getCommandeEncoour()
      .then( response => {
        if(response.status === 200){
          response.json().then(data => { 
            
            data = cryptoService.decrypt(data.message, process.env.REACT_APP_SECRET_KEY) 
           
              setIsloading(false)
              setVideCommande(false)
              setPayed(data.payed)
              setNext(data.next)
              setPending(data.pending)
              setDocNum(data.docNum)
              setFerme(data.isclosed)
              setNombreMois(data.numberMonth)
              
              
          })
        }
        else if (response.status === 403) {
              navigate("/auth/login") 
            }
        else {
          setIsloading(false)
        }
      })
      
  }
  return () => flag.current = true
  }, [])

  const [boutiqueCoordonnees,setBoutiqueCoordonnee] = useState([])
  const getAgences = () => {
    clientService.getAgentForPaiment()
        .then(response => {
          if (response.status === 200) {
            setAfficheModalPayer(true)
            response.json().then(data => {
              data = cryptoService.decrypt(data.message, process.env.REACT_APP_SECRET_KEY)
              
              setBoutiqueCoordonnee(data)
              console.log(data)
          })
        }
      })
  }
  if (isloading) {
    return <Loading  />
  }
  return (
    <>
          <Modal
            isShowing={afficheModalPayer}
            hide={setAfficheModalPayer}
            title=""
      >
        {
          boutiqueCoordonnees.length > 0 ?
            <div>
              {
                boutiqueCoordonnees.map((boutique, i) => (
                  <div key={i}>
                    <h1 className='text-xl font-bold'> coordonnées de la boutique : {boutique.shop["name"] }</h1>
                    <div>
                      <p>Tél : {boutique.shop['phone']} </p>
                      <p>Email : {boutique.shop['email']} </p>
                      <p>Adresse : {boutique.shop['address']} </p>
                      <div className='flex flex-row mt-3 mb-3'>
                         <p className='mt-10'>Localistation : </p><iframe className='h-20 w-24 rounded-xl  ml-3'
                      src={`https://maps.google.com/maps?q=${boutique.shop['latitude']},${boutique.shop['longe']}&hl=es;&output=embed`} title='localistaion' aria-hidden="true"  ></iframe>
                      </div>
                    </div>
                    <div>
                      <h1 className='text-xl font-bold'>Compte paiment</h1>
                      
                      {
                        boutique.compte.map((coor, i) => (
                          <div key={i}>
                            <div className='flex flex-row mb-3 mt-3'>
                              <img src={coor.profileImg} alt="image" className='rounded-xl w-14 h-14' />
                              <p className='font-semibold m-5'>{coor.numCount} </p>
                            </div>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                ))
              }
            </div> : <p></p>
        }
           {/* {!!coordonneesAgentShop && <div>
                <p>Nom : {coordonneesAgentShop['name']} </p>
                <p>Tél : {coordonneesAgentShop['phone']} </p>
                <p>Email : {coordonneesAgentShop['email']} </p>
                <p>Adresse : {coordonneesAgentShop['address']} </p>
            </div>
            }
          {coordonneesAgentCompe.length > 0 ?
          <div>
             <h2 className='mt-5 text-xl font-bold'>Compte de paiement</h2>
              {
                coordonneesAgentCompe.map((coor, i) => (
                  <div>
                    <div className='flex flex-row mb-3 mt-3'>
                      <img src={coor.profileImg} alt="image" className='rounded-xl w-14 h-14' />
                      <p className='font-semibold m-5'>{coor.numCount} </p>
                    </div>
                  </div>
                ))
              }
            </div>
            : <p></p>
          } */}
                
           
          </Modal>
      { videCommande ? <div className="bg-blue-100 w-2/5 max-sm:ml-40 md:ml-80 mt-1  border-t border-b border-blue-500 text-blue-700 px-4 py-3" role="alert">
          <p className="font-bold">Information</p>
          <p className="text-sm">Vous n'avez pas encore de commande en cours.</p>
        </div>:
        <div className="bg-white dark:bg-gray-900">
           <div className="container px-6 py-8 mx-auto">
              <h1 className="text-3xl font-semibold text-center text-gray-800 capitalize lg:text-4xl dark:text-white">Votre commande en cours</h1>
              
              <p className="max-w-2xl mx-auto mt-4 text-center text-gray-500 xl:mt-6 dark:text-gray-300">
                  Vous trouverez ici l'ensemble de vos échéances pour votre commande. Veuillez payer régulièrement vos tranches avant que le téléphone ne soit bloqué.
              </p>
               <p className='max-w-2xl mx-auto mt-4 text-center font-bold text-gray-600 xl:mt-6 dark:text-gray-300'>Votre numéro de facture : {docNum }</p>
              
              <div>
                  <span className="text-2xl font-bold">{Math.round(((payed.length)*100)/nombreMois)}%</span>
                  <div className="relative">
                      <div className="w-full bg-gray-200 rounded-sm h-8">
                          <div className="bg-green-500 h-full rounded-sm" style={{width: `${((payed.length)*100)/nombreMois}%`}}></div>
                      </div>
                  </div>
              </div>
              <div className='grid grid-cols-1 gap-8 mt-6 lg:grid-cols-3 xl:mt-12'>
                
                { payed.length > 0 ?
                      <div className="">
                            {
                              payed.map((echeance,i)=>(
                                <div key={i} className="flex items-center justify-between px-8 py-4 border border-blue-500 cursor-pointer rounded-xl">
                                <div className="flex flex-col items-center space-y-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 text-green-500 sm:h-7 sm:w-7" viewBox="0 0 20 20" fill="currentColor">
                                      <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                                    </svg>
                                    <h2 className="text-lg font-medium text-gray-700 sm:text-xl dark:text-gray-200">Payé </h2>
                                </div>
                                
                                
                                <div className="flex flex-col items-center space-y-1">
                                    <div className="px-2 text-xs text-blue-500 bg-gray-100 rounded-full dark:text-blue-400 sm:px-4 sm:py-1 dark:bg-gray-700 ">
                                        Enregistré {((payed.length)*100)/nombreMois}%
                                    </div>

                                    <h2 className="text-2xl font-semibold text-blue-600 dark:text-blue-500 sm:text-3xl">{echeance.docTotal} FCFA </h2>
                                </div>
                            </div>
                              ))
                            }

                    </div>: 
                    <p></p>
                }
                { next.length > 0 ?
                <div className="">
                  
                        {
                          next.map((echeance,i)=>(
                            <div key={i} className="flex items-center justify-between px-3 py-4 w-full border border-blue-500  rounded-xl">
                            <div className="flex flex-col items-center space-y-1">
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 text-yellow-500 sm:h-7 sm:w-7" viewBox="0 0 20 20" fill="currentColor">
                                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                                </svg>
                                <h2 className="text-lg font-medium text-gray-700 sm:text-xl dark:text-gray-200 m-2">A payer le: {new Date(echeance.dueDate).toISOString().replace("T"," ").split(" ")[0] } </h2>
                            </div>
                            
                            <div className="flex flex-col items-center space-y-1">
                                <div className="px-2 text-xs text-blue-500 bg-gray-100 rounded-full dark:text-blue-400 sm:px-4 sm:py-1 dark:bg-gray-700 ">
                                    Enregistré {100/nombreMois}%
                                </div>

                                <h2 className="text-2xl font-semibold text-blue-600 dark:text-blue-500 sm:text-3xl">{echeance.docTotal} FCFA </h2>
                            </div>
                        </div>
                          ))
                        }
                    </div>: 
                    <p></p>
                  }
                  
                  { pending.length > 0 ?
                    <div className="">
                        {
                          pending.map((echeance,i)=>(
                            <div key={i} className="flex items-center justify-between px-3 py-4 border border-blue-500 cursor-pointer rounded-xl">
                            <div className="flex flex-col items-center space-y-1">
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 text-gray-500 sm:h-7 sm:w-7" viewBox="0 0 20 20" fill="currentColor">
                                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                                </svg>
                                <h2 className="text-lg font-medium text-gray-700 sm:text-xl dark:text-gray-200">A payer le: {new Date(echeance.dueDate).toISOString().replace("T"," ").split(" ")[0] }</h2>
                            </div>
                            
                            <div className="flex flex-col items-center space-y-1">
                                <div className="px-2 text-xs text-blue-500 bg-gray-100 rounded-full dark:text-blue-400 sm:px-4 sm:py-1 dark:bg-gray-700 ">
                                    Enregistré {((nombreMois-(payed.length+1))*100)/nombreMois}%
                                </div>

                                <h2 className="text-2xl font-semibold text-blue-600 dark:text-blue-500 sm:text-3xl">{echeance.docTotal} FCFA </h2>
                            </div>
                        </div>
                          ))
                        }
                    </div>: 
                    <p></p>
                  }
              </div>
              <div className="p-8 mt-8 space-y-8 bg-gray-100 dark:bg-gray-800 rounded-xl">
                { payed.length > 0 ?
                <div className="flex items-center justify-between text-gray-800 dark:text-gray-200">
                      <p className="textlg sm:text-xl">Les paiements déjà enregistrés</p>

                      <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 text-green-500 sm:h-7 sm:w-7" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                      </svg>
                </div> :
                <p></p>
                  }
                  { next.length > 0 ?
                    <div className="flex items-center justify-between text-gray-800 dark:text-gray-200">
                        <p className="textlg sm:text-xl">Le paiement en cours</p>

                        <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 text-yellow-500 sm:h-7 sm:w-7" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                        </svg>
                      </div> :
                    <p></p>
                    }
                    {
                      pending.length > 0 ?
                      <div className="flex items-center justify-between text-gray-800 dark:text-gray-200">
                      <p className="textlg sm:text-xl">Les paiements à venir</p>
                      <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 text-gray-500 sm:h-7 sm:w-7" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                      </svg>
                      </div> :
                      <p></p>
                    }
                  </div>
                  
                { !ferme &&
                  <div className="flex justify-center mt-5">
                      <button onClick={()=>{getAgences()}} className="px-8 py-2 tracking-wide text-white capitalize transition-colors duration-200 transform bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus:bg-blue-500 focus:ring focus:ring-blue-300 focus:ring-opacity-80">
                          payer
                      </button>
                  </div>
                }
           </div> 
        </div>
          }
    </>
  )
}

export default Commandes
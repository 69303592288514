import React, { useEffect, useState } from "react";
import { Link,useNavigate } from "react-router-dom";
import {
  Button,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
} from "@material-tailwind/react";
import { accountService } from "../../services/Account.service";
import { phoneServices } from "../../services/client/phone";
import { clientService } from "../../services/client/client";


function Nav() {

  //status 200 : le client a une estimation en cours
  //status 404: Le client n'a pas d estimation en cour dans ce cas faut appeler l'autre api
  //status 500: error serveur 

  let navigate = useNavigate()
  
  const [suivicommande, setSuiviCommande] = useState(false)
  const [suiviEstimation, setSuiviEstimation] = useState(false)
  
   useEffect(()=>{
        phoneServices.checkEstimation()
        .then(res=>{
            if(res.status === 200){
                res.json().then(data => {  
                  setSuiviEstimation(true)
                })
          }
          else if (res.status === 403) {
                    navigate("/auth/login") 
                  }
            else if (res.status === 404) {
              clientService.getCommandeEncoour()
                .then(response => {
                  if (response.status === 200) {
                    setSuiviCommande(true)   
                  }
                  else if (response.status === 403) {
                    navigate("/auth/login") 
                  }
                  else {
                    return 
                  }
              })
          }
            
        })
  }, [])
  
  const [menuOpen, setMenuOpen] = useState(false);
  function toggleMenu() {
    setMenuOpen(!menuOpen);
  }
  
  const firstName = localStorage.getItem('firstName')
  const lastName = localStorage.getItem('lastName')
  const email = localStorage.getItem('email')

  
    const logout = () => {
        accountService.logout()
        navigate('/auth/login')
    }
  return (
    <nav className="flex flex-wrap items-center justify-between p-3 bg-white">
      <Link to="/client/compte/home">
        <img
        src="/images/logoAccueil.png"
        className="ml-3 h-44 w-44 rounded-2xl"
        alt="ACME"
        width="120"
      />
      </Link>
      <div className="flex md:hidden">
        <button id="hamburger" onClick={toggleMenu}>
          {menuOpen ? (
            <img
              alt="logo"
              className="toggle block"
              src="https://img.icons8.com/fluent-systems-regular/2x/close-window.png"
              width="40"
              height="40"
            />
          ) : (
              <Link to="/client/compte/home">
                <img
                  className="toggle block"
                  src="https://img.icons8.com/fluent-systems-regular/2x/menu-squared-2.png"
                  width="40"
                  height="40"
                  alt="logo"
                />
              </Link>
          )}
        </button>
      </div>
      <div
        className={`toggle ${menuOpen ? "" : "hidden"} w-full md:w-auto md:flex text-right text-bold mt-5 md:mt-0 border-t-2 border-blue-900 md:border-none`}
      >
        <Link
          to="/client/compte/home"
          className="m-2 block md:inline-block text-[#0A3F65] hover:bg-[#0A3F65] hover:rounded-xl hover:text-white px-2 py-3 border-b-2 border-blue-900 md:border-none "
        >
          Accueil
        </Link>
       { (!suiviEstimation && !suivicommande) && <Link
          to="/client/compte/form-estimation"
          className="m-2 block md:inline-block text-[#0A3F65] hover:bg-[#0A3F65] hover:rounded-xl hover:text-white px-3 py-3 border-b-2 border-blue-900 md:border-none"
        >
          IziCall
        </Link> }
        
        { suivicommande &&
          <Link
          to="/client/compte/commandes"
          className="m-2 block md:inline-block text-[#0A3F65] hover:bg-[#0A3F65] hover:rounded-xl hover:text-white px-3 py-3 border-b-2 border-blue-900 md:border-none"
        >
          Suivi Commande
          </Link>
        }
        { suiviEstimation &&
          <Link
          to="/client/compte/suivi-estimation"
          className="m-2 block md:inline-block text-[#0A3F65] hover:bg-[#0A3F65] hover:rounded-xl hover:text-white px-3 py-3 border-b-2 border-blue-900 md:border-none"
        >
          Suivi Estimation
          </Link>
        }
        <Menu>
            <MenuHandler>
            <Button variant="gradient">
                <img className="w-10 h-10 " src="/images/avatar.png" alt="Rounded avatar"></img>
            </Button>
            </MenuHandler>
            <MenuList>  
            <MenuItem className="mt-2"> {firstName} {lastName}  </MenuItem> 
            <MenuItem className="mt-2">{email} </MenuItem>
            <MenuItem className="mt-2">
              <Link to="/client/compte/updatePassword">modifier mot de passe</Link>
            </MenuItem>
            <MenuItem onClick={logout} className="mt-2">Déconnexion</MenuItem>
            </MenuList>
        </Menu>
      </div>
    </nav>
  );
}

export default Nav;

import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { clientService } from '../../services/client/client';
import { cryptoService } from '../../services/crypto.service';
import AlertMessage from '../../utils/AlertMessage';
import { accountService } from '../../services/Account.service';
import logo from '../../images/bckgroundLogin.jpg'
import NavBar from '../../components/public/Navbar'

const Login = () => {
    let navigate = useNavigate()
  const [credential,setCredential] = useState({
     login:'',
     password: ''
  })
  const [errorLogin,setErrorLogin] = useState(false)
  const [isloading,setIsloading] = useState(false)
  const onChange = (e) =>{
    setCredential({
      ...credential,
      [e.target.name]:e.target.value
  })
  }

  const handleLogin = (e) =>{
    e.preventDefault()
    setIsloading(true)
    clientService.login(cryptoService.encryptLoginClient(credential))
    .then( response => {
        if(response.status ===200){
            response.json().then(data =>{
                data = cryptoService.decrypt(data.message, process.env.REACT_APP_SECRET_KEY) 
                console.log(data)
                accountService.saveToken(data.accessToken)
                accountService.saveUserInfo(data.firstName, data.lastName, data.email)
                setIsloading(false)     
                navigate("/client/compte/home", {replace: true})    
            })
        }
        else{
            setErrorLogin(true)
            setIsloading(false) 
            
        }
    })   
    
  }

 
  return (
    <>
    
      <NavBar />
    <div className="relative">
        
  <img src={logo} className="absolute inset-0 h-full w-full object-cover" alt="" />
  <div className="relative bg-blue-300 bg-opacity-90">
    <svg className="absolute inset-x-0 -bottom-1 text-white" viewBox="0 0 1160 163">
      <path fill="currentColor" d="M-164 13L-104 39.7C-44 66 76 120 196 141C316 162 436 152 556 119.7C676 88 796 34 916 13C1036 -8 1156 2 1216 7.7L1276 13V162.5H1216C1156 162.5 1036 162.5 916 162.5C796 162.5 676 162.5 556 162.5C436 162.5 316 162.5 196 162.5C76 162.5 -44 162.5 -104 162.5H-164V13Z"></path>
    </svg>
    <div className="relative mx-auto overflow-hidden px-4 py-16 sm:max-w-xl md:max-w-full md:px-24 lg:max-w-screen-xl lg:px-8 lg:py-20">
      <div className="flex flex-col items-center justify-between xl:flex-row">
        <div className="mb-12 w-full max-w-xl xl:mb-0 xl:w-7/12 xl:pr-16">
          <h2 className="mb-6 max-w-lg font-sans text-3xl font-bold tracking-tight text-white sm:text-7xl sm:leading-none">IZICALL</h2>
          <p className="mb-4 max-w-xl  text-[#0A3F65] text-xl md:text-lg">L’échange de téléphone se fait dans votre espace personnel. Pour cela, vous devez vous inscrire et vous connecter à notre plateforme IZICALL pour vous souscrire à l’offre.</p>
          
        </div>
        <div className="w-full max-w-xl xl:w-5/12 xl:px-8 mt-10">
          <div className="overflow-hidden rounded-xl border-t-4 border-gray-200 bg-white p-7 shadow-2xl  sm:p-10">
            <h3 className="mb-4 text-xl font-bold text-emerald-900 sm:mb-6 sm:text-center sm:text-2xl">Connexion</h3>
            {
                errorLogin &&
                <AlertMessage  color="red" onClick={setErrorLogin} titre="Désolé" message="mot de passe incorrect!!!!"  />
             
            }
            {
                isloading &&
                <div className='mt-10'>  
                    <div className="flex items-center justify-center ">
                        <div className="w-32 h-32 border-t-4 border-b-4 border-green-900 rounded-full animate-spin"></div>
                    </div>
                </div>
            }
            <form onSubmit={handleLogin} >
            <div className="mb-1 sm:mb-2">
                <p className="mb-2 h-12 w-full"></p>
               </div>
              <div className="mb-1 sm:mb-2">
                <label htmlFor="login" className="mb-1 inline-block font-medium text-emerald-900">Email ou Téléphone</label>
                <input    name="login"  onChange={onChange} placeholder="exemple@gmail.com ou 7708700000" required="" type="text" className="mb-2 h-12 w-full flex-grow appearance-none rounded border border-gray-300 bg-white px-4 shadow-sm ring-blue-200 transition duration-200 focus:border-blue-400 focus:outline-none focus:ring" id="login"  />
              </div>
              <div className="mb-1 sm:mb-2">
                <label htmlFor="email" className="mb-1 inline-block font-medium text-emerald-900">Mot de passe</label>
                <input name="password" onChange={onChange} placeholder="*****************" required="" type="password" className="mb-2 h-12 w-full flex-grow appearance-none rounded border border-gray-300 bg-white px-4 shadow-sm ring-blue-200 transition duration-200 focus:border-blue-400 focus:outline-none focus:ring" id="password" />
                </div>
                <div className="flex justify-center items-center mt-6">
                <div   className="inline-flex items-center text-gray-700 font-medium text-xs text-center">
                  <span className="ml-2">Mot de passe oublié?
                      <Link to="/reset-password" className="text-xs ml-2 text-blue-500 font-semibold">Cliquer ici</Link>
                  </span>
                </div>
              </div>
              <div className="mt-4 mb-2 sm:mb-4">
                <button type="submit" className="inline-flex h-12 w-full items-center justify-center rounded-xl bg-blue-600 px-6 font-medium tracking-wide text-white shadow-md ring-blue-200 transition duration-200 hover:bg-blue-700 focus:outline-none focus:ring">
                    Connecter
                </button>
              </div>
              <div className="flex justify-center items-center mt-6">
                <div   className="inline-flex items-center text-gray-700 font-medium text-xs text-center">
                  <span className="ml-2">Vous n'avez pas encore un compte?
                      <Link to="/client/inscription" className="text-xs ml-2 text-blue-500 font-semibold">s'inscrire ici</Link>
                  </span>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
      
    </>
  )
}

export default Login
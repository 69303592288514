import React, { useState } from 'react'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { clientService } from '../../../services/client/client'
import { cryptoService } from '../../../services/crypto.service'
import AlertMessage from '../../../utils/AlertMessage'
import Loading from '../../../utils/Loading'
import { StepperContext } from './contexts/StepperContext'

  
const Identifiants = () => {
  const { userData, setUserData,pays } = useContext(StepperContext)
  const [passwordConfirm, setPasswordConfirm] = useState('')
  let navigate = useNavigate()

  const [isloading,setIsloading] = useState(false)
  const [errorCreation, setErrorCreation] = useState(false)
  const [inputVide, setInputVide] = useState(false)
  const [errorPassword, setErrorPassword] = useState(false)
  const [validPhone,setValidPhone] = useState(false)
  const handleChange = (e) => {
        const { name, value } = e.target
        setUserData({...userData, [name] : value})
  }
  
  const [showSuccess,setShowSuccess] = useState(false)
  const handleFormulaire = () => {
    
    userData.pays = pays
    if(userData.password !==passwordConfirm ){
        setErrorPassword(true)
        return 
    }
    else if (userData.lastName === '' || userData.firstName === '' || userData.email === '' || userData.phone === '') {
      setInputVide(true)
      return
    }
      else if (userData.phone === '' ) {
      setValidPhone(true)
      return
    }
    else {
        setIsloading(true)
        clientService.createClient(cryptoService.encryptCreateClient(userData))
        .then( response => {
            if(response.status ===200){
                response.json().then(data =>{   
                  setIsloading(false)
                  setUserData({
                    firstName: '',
                    lastName:  '',
                    phone:  '',
                    email: '',
                    password: '',
                    userProfil: 'online',
                    pays :'',
                    CNI :'',
                    address: '',
                    profession:''
                  })
                  setShowSuccess(true);
            setTimeout(() => {
                  navigate("/auth/login", {replace: true}) 
              }, 4000); 
                      
                })
            }
            else{
                setErrorCreation(true)
                setIsloading(false)
            }
        })
    }
    
  } 
  return (
    <div className='flex flex-col'>
            {
              isloading && <Loading  />                
            }
            {
                errorPassword &&
                    <AlertMessage  color="red" onClick={setErrorPassword} titre="Désolé" message="les mots de passe ne correspondent pas"  />
            } 
            {
                showSuccess &&
                    <AlertMessage  color="green" onClick={setShowSuccess} titre="Super!" message="Votre compte a été bien créé"  />
            } 
            {
                errorCreation &&
                <AlertMessage color="red" onClick={setErrorCreation} titre="Hoops!!!" message="Erreur de création de l'utilisateur."  /> 
            }
            {
                inputVide &&
                <AlertMessage color="red" onClick={setInputVide} titre="Hoops!!!" message="Vous devez remplir les champs obligatoire et respecter les formats."  /> 
              }
              {
                validPhone &&
                <AlertMessage color="red" onClick={setInputVide} titre="Hoops!!!" message="Veuillez respecter le format de votre numéro de téléphone."  /> 
            }
          <div className='w-full mx-2 flex-1'>
              <div className='font-bold h-6 mt-3 text-gray-500 text-xs leading-8 uppercase'>
                 Mot de passe <span className='text-red-600'>*</span> 
              </div>
              <div className='bg-white my-2 p-1 flex border border-gray-200 rounded'>
                  <input
                      onChange={handleChange}
                      value={userData.password}
                      name="password"
                      placeholder='Votre mot de passe'
                      className='p-1 px-2 appearance-none outline-none w-full text-gray-800'
                      type="password" required pattern="[0-9]*" />
              </div> 
          </div>
          <div className='w-full mx-2 flex-1'>
              <div className='font-bold h-6 mt-3 text-gray-500 text-xs leading-8 uppercase'>
                 Confirmer mot de passe 
              </div>
              <div className='bg-white my-2 p-1 flex border border-gray-200 rounded'>
                  <input
                      onChange={(e)=>setPasswordConfirm(e.target.value)}
                      value={passwordConfirm}
                      name="passwordConfirm"
                      placeholder='Confirmer votre mot de passe'
                      className='p-1 px-2 appearance-none outline-none w-full text-gray-800'
                      type="password" />
              </div> 
          </div>
      <button type="submit" onClick={() => handleFormulaire()}
                    className="flex mt-2 items-center justify-center focus:outline-none text-white text-sm sm:text-base bg-blue-500 hover:bg-blue-600 rounded-2xl py-2 w-full transition duration-150 ease-in "
          >
              <span className="mr-2 uppercase">Enregistrer</span>
              <span>
              <svg
                  className="h-6 w-6"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
              >
                  <path
                  d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
              </svg>
              </span>
      </button>
    </div>
  )
}

export default Identifiants
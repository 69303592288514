
export default [
 
  {
   
    urls: "images/A33_vers_A34.png",
  },
  {
   
    urls: 'images/a23_vers_A24.png',
  },
  {
    
    urls: 'images/a13_vers_a14.png',
  },
  
];

import React from 'react';

function PaytechButton({ transactionId }) {
 // const [error, setError] = useState(null);

  const handleClick = async () => {
    try {
      const response = await fetch('https://sample.paytech.sn/paiement.php', {
        method: 'POST',
        headers: {},
        body: JSON.stringify({ transactionId }),
      });
      const jsonResponse = await response.json();
      if (!response.ok) {
        //setError(jsonResponse);
        console.log(jsonResponse);
        return;
      }
    } catch (e) {
      //setError(e);
      // console.log(e);
    }
  };

  
  return (
    <button onClick={handleClick}>
      Payer cette transaction
    </button>
  );
}

export default PaytechButton;

import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { phoneServices } from '../../../services/client/phone'
import { cryptoService } from '../../../services/crypto.service'
import Loading from '../../../utils/Loading'
import Modal from '../../../utils/Model'
import { useNavigate } from 'react-router-dom'

import {BsFillInfoSquareFill} from 'react-icons/bs'

const FormulaireSiteWeb = () => {

    let navigate = useNavigate()
    
    const [models, setModels] = useState([])
    const [model,setmodel] = useState()
    const [isloading,setIsloading] = useState(false)
    const [modelEch,setModelEch] = useState(null)
    const [modelsEch,setModelsEch] = useState([])
    const [afficheEstimation, setAfficheEstimation] = useState(false)
    
    const [cameraCheck, setCameraCheck] = useState(true)
    const [memoires, setMemoires] = useState([])
    
    useEffect(()=>{
        phoneServices.getAllModel()
        .then(res=>{
            if(res.status === 200){
                res.json().then(data => {  
                    
                    data = cryptoService.decrypt(data.message,process.env.REACT_APP_SECRET_KEY)  
                    setModels(data.map(e=> ({
                       value: e.model,
                       label: `Samsung ${e.model}`
 
                    })))
                })
            }
            else if (res.status === 403) {
                navigate("/auth/login") 
            }
        })
    }, [])
    
    useEffect(()=>{
        const information = cryptoService.encrypt(modelEch,process.env.REACT_APP_SECRET_KEY)
        phoneServices.getModelChoise(information)
        .then(res=>{
            if(res.status === 200){
                res.json().then(data => {   
                    data = cryptoService.decrypt(data.message, process.env.REACT_APP_SECRET_KEY) 
                    console.log(data)
                    setCameraCheck(data.cameraCheck.frontCamera)
                    setModelsEch(data.model.map(e=> ({
                       value: e.model,
                       label: `Samsung ${e.model}`
                    })))
                    setMemoires(data.type)
                })
            }
        })
    },[modelEch])
//  *#06#
    const [phoneData,setPhoneData] = useState({
        serialNumber:'',
        model: '',
        modelEch:'',
        sub: '',
        frontCamera: '',
        phone: '',
        rearCamera: '',
        motor: '',
        screen: '',
        memory: ''
    })

    const onChange = (e)=>{
        setPhoneData({
            ...phoneData,
            [e.target.name]: e.target.value
        })
    }
    const[price,setPrice] = useState()
    const[priceOld,setPriceOld] = useState()
    const[estimation,setEstimation] =useState()
    const [slice,setSlice] = useState()
    const[nombreMois,setNombreMois] = useState()
    const [dataEstimation, setDataEstimation] = useState()
    
    const handleEstimation = (e) =>{
        e.preventDefault()
        phoneData.model=model
        phoneData.modelEch = modelEch
        setIsloading(true)
        const information = cryptoService.encrypt(phoneData,process.env.REACT_APP_SECRET_KEY)
        phoneServices.sendFormulaire(information)
        .then(response=>{
            if(response.status ===200){
                setIsloading(false)
                setAfficheEstimation(true)
                response.json().then(data =>{ 
                    data = cryptoService.decrypt(data.message,process.env.REACT_APP_SECRET_KEY) 
                    console.log(data)
                    setDataEstimation(data)
                    setEstimation(data.estimation)
                    setPrice(data.priceChoice)
                    setPriceOld(data.priceOldPhone)
                    setSlice(data.slice)
                    setNombreMois(data.month)
                })
            }
        })
        
    }

    const [saveClient,setSaveClient] = useState(false)
    const [messageDejaDemande, setMessageDejaDemande] = useState('')
    const [afficheMessageDejaDemannde,setAfficheMessageDejaDemande] = useState(false)
    const [isloadingSaveForm, setIsloadingSaveForm] = useState(false)
    
    const handleSaveFormulaire = () => {
        const estim = cryptoService.encrypt(dataEstimation,process.env.REACT_APP_SECRET_KEY)
        setIsloadingSaveForm(true)
        phoneServices.saveFormulaire(estim)
        .then(response=>{
            if(response.status === 200){
                response.json().then(data =>{  
                    setSaveClient(true)
                    setIsloadingSaveForm(false)
                })
            }
            else if (response.status === 300) {
                response.json().then(data =>{  
                    setMessageDejaDemande(data.message)
                    setAfficheMessageDejaDemande(true)
                    setIsloadingSaveForm(false)
                })
            }
        })   
    }

    const [infoModel,setInfoModel] = useState(false)
    const [infoMoteur,setInfoMoteur] = useState(false)
    const [infoSerialNumber, setInfoSerialNumber] = useState(false)
    
  return (
    <div className="relative h-screen w-full  bg-[url('images/bckgroundAccueil.jpg')] bg-no-repeat bg-center bg-fixed bg-cover"> 

            <div className="max-w-5xl p-5 mx-auto bg-[#0A3F65] rounded-md shadow-md dark:bg-gray-800 mt-6" >
            {
                isloading &&
                <Loading />
                }   
                  
                <Modal
                    isShowing={afficheEstimation}
                    hide={setAfficheEstimation}
                    title="Estimation de l'échange"
                >
                  {
                      afficheMessageDejaDemannde && <p className='font-semibold text-red-500'>{messageDejaDemande} </p>
                  } 
                  { isloadingSaveForm &&
                      <div className="border-gray-300 ml-32 md:ml-48 h-14 w-14 animate-spin rounded-full border-8 border-t-blue-600" />
                  }
                  {
                    saveClient &&
                        <div className="bg-blue-100 border border-blue-400 text-blue-700 px-4 py-3 rounded relative" role="alert">
                                <strong className="font-bold">Super!</strong>
                                <span className="block sm:inline">L'enregistrement a été bien pris en compte.</span>
                                <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                                  <svg onClick={() => { setSaveClient(false); window.location.reload()}} className="fill-current h-6 w-6 text-blue-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
                                </span>
                        </div>
                    }   
                    <div className="p-10 rounded-md shadow-md bg-white ">
                        
                        <div className="flex justify-between">
                            <p className="text-gray-700">Le prix du {model} est: </p>
                            <p className="text-gray-700 ml-1">{priceOld} CFA </p>
                        </div>
                        <div className="flex justify-between">
                            <p className="text-gray-700">Le prix du {modelEch} est:</p>
                            <p className="text-gray-700 ml-1">{price} CFA </p>
                        </div>
                        <div className="flex justify-between">
                            <p className="text-gray-700">Le nombre d'échéances est: </p>
                            <p className="text-gray-700 ml-1">{nombreMois}</p>
                        </div>
                        <div className="flex justify-between">
                            <p className="text-gray-700">La tranche mensuelle est : </p>
                            <p className="text-gray-700 ml-1">{slice} CFA</p>
                        </div>
                        <hr className="my-4" />
                        <div className="flex justify-between">
                        <p className="text-lg font-bold">Estimation totale:</p>
                        <div className="ml-1">
                            <p className="mb-1 text-lg font-bold">{estimation} CFA </p>
                        </div>
                        </div>
                        <div className=" mt-3 flex p-4 mb-4 text-sm text-blue-700 bg-blue-100 rounded-lg dark:bg-gray-800 dark:text-blue-400" role="alert">
                            <svg aria-hidden="true" className="flex-shrink-0 inline w-5 h-5 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
                            <span className="sr-only">Info</span>
                            <div>
                                <span className="font-medium">Information!</span> Cette estimation n'est pas définitive et peut être modifiée si toutefois les informations de votre téléphone sont erronées.
                            </div> 
                          </div>
                          <div className=" mt-3 flex p-4 mb-4 text-sm text-yellow-700 bg-yellow-100 rounded-lg dark:bg-gray-800 dark:text-yellow-400" role="alert">
                            <svg aria-hidden="true" className="flex-shrink-0 inline w-5 h-5 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
                            <span className="sr-only">Info</span>
                            <div>
                                <span className="font-medium">Information!</span> Une fois cette estimation validée vous ne pouvez plus faire une autre tant que cette dernière est en cours.
                            </div>
                            
                        </div>
                    </div>
                        
                        <div className=' flex justify-between mt-2 '>
                        <button  onClick={handleSaveFormulaire}  type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
                            Enregistrer
                          </button>
                          <button  onClick={()=>setAfficheEstimation(false)}  type="button" className="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:red-blue-600 dark:hover:red-blue-700 focus:outline-none dark:focus:ring-red-800">
                            Annuler
                        </button>
                    </div>
                </Modal>
        
                <Modal
                    isShowing={infoModel}
                    hide={setInfoModel}
                    title="Comment trouver le model de votre téléphone ?"
                  >
                      Allez dans paramètre puis dans A propos 
                  </Modal>
                  <Modal
                    isShowing={infoSerialNumber}
                    hide={setInfoSerialNumber}
                    title="Comment trouver le numéro de série de votre téléphone ?"
                  >
                      Tapez *#06#
                  </Modal>
                  <Modal
                    isShowing={infoMoteur}
                    hide={setInfoMoteur}
                    title="Comment savoir si le moteur marche?"
                  >
                      Maintenez la touche qui l'éteint et la touche ...
                </Modal>
            <form  onSubmit={handleEstimation} className='md:ml-60'>
                <div className='mt-2' >
                    <h2 className="text-2xl  font-bold text-yellow-400">Informations du téléphone</h2>
                      <div className='flex flex-row justify-between  mt-2 md:w-2/3'> 
                            <div className='w-full'>
                                <Select  
                                    placeholder="Model de votre téléphone"
                                    name="model"
                                    options={models}
                                    onChange={(e)=>{setmodel(e.value);setModelEch(e.value)}}
                                    className="basic-multi-select mb-2"
                                    classNamePrefix="select"
                            />
                            </div>
                              <span onClick={()=>setInfoModel(true)} className="cursor-pointer ml-1">
                                  <BsFillInfoSquareFill size={40} color="white" />
                              </span>
                        </div>    
                            <Select  
                            placeholder="Model du téléphone choisi"
                            name="modelEch"
                            options={modelsEch}
                            onChange={(e)=>setModelEch(e.value)}
                            className="basic-multi-select md:w-2/3"
                            classNamePrefix="select"
                              />
                              
                          
                          
                        <div  className='flex flex-row justify-between md:w-2/3'>
                            <input type="text"  name='serialNumber' onChange={onChange}
                                className="w-full block  px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring" 
                                placeholder="Numéro de serie " />
                                <span onClick={()=>setInfoSerialNumber(true)} className="cursor-pointer mt-2 ml-1">
                                    <BsFillInfoSquareFill size={40} color="white" />
                               </span>
                        </div>
                        
                </div>
                <div className='mt-2 md:w-2/3' >
                          <h2 className="text-2xl  font-bold text-yellow-400">Informations du matériel</h2>
                    <select name='sub' onChange={onChange}
                        className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring" >
                    <option value=" ">Port USB</option>
                    <option value="oui">Marche</option>
                    <option value="non" >Ne marche pas</option> 
                </select>
                    { memoires.length > 0?
                        <div>
                            <select  name='memory' onChange={onChange}
                                    className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring" >
                           <option value=" ">Mémoire Téléphone</option>
                              { memoires.map((memory,i)=>(
                                    <option key={i} value={memory} >{memory} Go</option> 
                                    ))
                                }
                                </select>
                        </div>
                        : <p></p>
                    }
                    { cameraCheck && <div >
                    <select name='frontCamera' onChange={onChange}
                            className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring" >
                        <option value=" ">Caméra avant</option>
                        <option value="oui">marche</option>
                        <option value="non" >Ne marche pas</option>     
                    </select>
                    </div>}
                    <div >
                    <select name='rearCamera' onChange={onChange}
                            className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring" >
                        <option value=" ">Caméra arrière</option>
                        <option value="oui">marche</option>
                        <option value="non" >Ne marche pas</option>      
                    </select>
                    </div>
                    <div  className='flex flex-row justify-between'>
                        <select name='motor' onChange={onChange}
                                className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring" >
                            <option value=" ">Moteur </option>
                            <option value="oui">marche</option>
                            <option value="non" >Ne marche pas</option>      
                        </select>
                        <span onClick={()=>setInfoMoteur(true)} className='mt-2 cursor-pointer ml-1'>
                            <BsFillInfoSquareFill size={40} color="white" />
                        </span>
                    </div>
                    <div >
                    <select name='screen' onChange={onChange} 
                            className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring" >
                        <option value=" ">Ecran téléphone</option>
                        <option value="non">Cassure </option>
                        <option value="oui" >Non cassure</option>      
                    </select>
                    </div>
                </div> 
                <button type='submit' className="bg-gray-800 hover:bg-gray-900 text-white font-bold py-2 max-sm:w-full mt-3 md:w-2/3 px-4 rounded">
                Envoyer
                </button>
            </form>
        </div>
    </div>
  )
}

export default FormulaireSiteWeb
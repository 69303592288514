import React from 'react'
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { phoneServices } from '../../../services/client/phone';
import { cryptoService } from '../../../services/crypto.service';
import Modal from '../../../utils/Model';

const ResultatEstim = () => {
    const location = useLocation().state.information;
    const [informations,setInformations] = useState(location)
    const [afficheFormModif,setAfficheFormModif] = useState(false)
    const [afficheResultat,setAfficheResultat] = useState(true)
    const [couleur,setCouleur] = useState(informations.color)
    const [serialNumber,setSerialNumber] = useState(informations.serialNumber)
    const [afficheListPrix,setAfficheListePrix] = useState(true)
    const [coutItems,setCoutItems] = useState([])
    const[price,setPrice] = useState()
    const[priceOld,setPriceOld] = useState()
    const[estimation,setEstimation] =useState()
    const [idFormulaire,setIdFormulaire] = useState()
    const [afficheDetail,setAfficheDetail] = useState(false)
    const [afficheEstimation,setAfficheEstimation] = useState(false)
    const [modelEch,setModelEch] = useState(null)
    const [model,setmodel] = useState()

    const onChange = (e)=>{
        setInformations({
            ...informations,
            [e.target.name]: e.target.value
        })
    }
    const handleValider = (e) => {
        e.preventDefault()
        informations.color = couleur
        informations.serialNumber = serialNumber
        const information = cryptoService.encrypt(informations,process.env.REACT_APP_SECRET_KEY)
        phoneServices.updateFormilaire(information,informations.id)
        .then(response=>{
            if(response.status ===200){
                setAfficheEstimation(true)
                response.json().then(data =>{ 
                    data = cryptoService.decrypt(data.message,process.env.REACT_APP_SECRET_KEY) 
                    if(data.object.lenght === 0){
                        setAfficheListePrix(false)
                    } 
                    setIdFormulaire(data.data.id_phone)
                    setCoutItems(data.object)
                    setEstimation(data.data.estimation)
                    setPrice(data.data.price)
                    setModelEch(data.data.modelTroc)
                    setmodel(data.data.model)
                    setPriceOld(data.data.priceOldPhone)
                })
            }
        })
    }
    const handleCancel =() =>{
        phoneServices.CancelModif(informations.id)
        .then(response=>{
            if(response.status ===200){
                setAfficheEstimation(true)
                response.json().then(data =>{ 
                    data = cryptoService.decrypt(data.message,process.env.REACT_APP_SECRET_KEY) 
                    setInformations(data)
                    setAfficheEstimation(false)
                    setAfficheFormModif(false)
                    setAfficheResultat(true)
                })
            }
        })
    }
  return (
    <div>
        <Modal
                isShowing={afficheEstimation}
                hide={setAfficheEstimation}
                title="Estimation de l'échange"
            >

                <div className="p-10 rounded-md shadow-md bg-white ">
                    <div className=" flex justify-between">
                        <p className="text-gray-700">Votre téléphone</p>
                        <p className="text-gray-700">{model}</p>
                    </div>
                    <div className="flex justify-between">
                    <p className="text-gray-700">Le téléphone choisi</p>
                    <p className="text-gray-700">{modelEch} </p>
                    </div>
                    <div className="flex justify-between">
                        <p className="text-gray-700">Le prix du téléphone choisi</p>
                        <p className="text-gray-700 ml-1">{price} CFA </p>
                    </div>
                    <div className="flex justify-between">
                        <p className="text-gray-700">Le prix de votre téléphone neuf</p>
                        <p className="text-gray-700 ml-1">{priceOld} CFA </p>
                    </div>
                    <hr className="my-4" />
                    <div className="flex justify-between">
                    <p className="text-lg font-bold">Estimation totale de l'échange</p>
                    <div className="ml-1">
                        <p className="mb-1 text-lg font-bold">{estimation} CFA </p>
                    </div>
                    </div>
                    <div className=" mt-3 flex p-4 mb-4 text-sm text-blue-700 bg-blue-100 rounded-lg dark:bg-gray-800 dark:text-blue-400" role="alert">
                        <svg aria-hidden="true" className="flex-shrink-0 inline w-5 h-5 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
                        <span className="sr-only">Info</span>
                        <div>
                            <span className="font-medium">Information!</span> Cette somme d'estimation n'est pas fixe. Elle peut être modifiable une fois que vous vous trompés sur les informations de votre téléphone
                        </div>
                        
                    </div>
                </div>
                    {  afficheListPrix &&
                        <div className="">
                        {coutItems?.map((item,i)=>(
                            <div key={i} className="flex justify-between">
                                <p className="text-gray-700"> {item.item} </p> 
                            
                            </div>
                        )
                        )}
                    
                    </div>
                    }
                    
                    <div className=' flex justify-between mt-2 '>
                    
                    <button  onClick={handleCancel}
                            className='text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 focus:outline-none dark:focus:ring-red-800'>
                        Annuler 
                    </button>
                </div>
        </Modal>
        { afficheResultat &&
          <div>
            <Modal
                isShowing={afficheDetail}
                hide={setAfficheDetail}
                title="Détails du téléphone"
                >
                <div className=" flex justify-between">
                    <p className="text-gray-700">Caméra </p>
                    <p className="text-gray-700">{informations.camera === "oui" ? "Marche" : "Ne marche pas"}</p>
                </div>
                <div className=" flex justify-between">
                    <p className="text-gray-700">Démarrage</p>
                    <p className="text-gray-700">{informations.boot === "oui" ? "Marche" : "Ne marche pas"}</p>
                </div>
                <div className=" flex justify-between">
                    <p className="text-gray-700">Connectivité</p>
                    <p className="text-gray-700">{informations.connectivity === "oui" ? "Marche" : "Ne marche pas"}</p>
                </div>
                <div className=" flex justify-between">
                    <p className="text-gray-700">Tactile</p>
                    <p className="text-gray-700">{informations.tactile === "oui" ? "Marche" : "Ne marche pas"}</p>
                </div>
                <div className=" flex justify-between">
                    <p className="text-gray-700">Ecran</p>
                    <p className="text-gray-700">{informations.screen === "oui" ? "Non Cassure" : "cassure"}</p>
                </div>
            </Modal>
            <div className="block w-full overflow-x-auto ">
            <table className="items-center bg-transparent w-full border-collapse ">
              <thead>
                <tr>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                Détails
                </th>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                              Estimation
                </th>
                 <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                couleur
                </th>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                Numero de serie
                </th>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                        Action
                </th>
                </tr>
              </thead>
      
              <tbody>
                <tr>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blueGray-700 ">
                 <button onClick={()=>setAfficheDetail(true)} >
                 <svg className="h-8 w-8 text-green-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
                    </svg>
                 </button>
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 ">
                    {informations.estimate}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 ">
                    {informations.color}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    <i className="fas fa-arrow-up text-emerald-500 mr-4"></i>
                    {informations.serialNumber}
                  </td>
                  <td>
                  <button  onClick={()=> {setAfficheFormModif(true);setAfficheResultat(false)}}  type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
                        Modifier
                    </button>
                  </td>
                </tr>
              
              </tbody>
      
            </table>
          </div>
       

          </div>
        }
        { afficheFormModif &&
            <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col my-2">
            <div className="-mx-3 md:flex mb-6">
                <div className="md:w-1/2 px-3 mb-6 md:mb-0">
                <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" >
                    Caméra
                </label>
                <select name='camera' onChange={onChange}
                    className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring" >
                    <option value={informations.camera}>{informations.camera === "oui" ? "Marche" :"Ne marche pas"} </option>
                    <option value="oui">Marche</option>
                    <option value="non" >Ne Marche pas</option> 
                </select>
                </div>
                <div className="md:w-1/2 px-3">
                <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2">
                    Demarrage
                </label>
                <select name='boot' onChange={onChange}
                    className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring" >
                    <option value={informations.boot}>{informations.boot === "oui" ? "Démarre" :"Ne démarre pas"} </option>
                    <option value="oui">Démarre</option>
                    <option value="non" >Ne Démarre pas</option> 
                </select>
                </div>
            </div>
            <div className="-mx-3 md:flex mb-2">
                <div className="md:w-1/2 px-3 mb-6 md:mb-0">
                <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" >
                    Couleur
                </label>
                <input value={couleur}  onChange={(e)=>setCouleur(e.target.value)}
                    className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4" id="grid-city" type="text" />
                </div>
                <div className="md:w-1/2 px-3 mb-6 md:mb-0">
                <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" >
                    Connectivé
                </label>  
                <select name='connectivity' onChange={onChange}
                    className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring" >
                    <option value={informations.connectivity}>{informations.connectivity === "oui" ? "Marche" :"Ne marche pas"} </option>
                    <option value="oui">Marche</option>
                    <option value="non" >Ne Marche pas</option> 
                </select>
                </div>
                <div className="md:w-1/2 px-3 mb-6 md:mb-0">
                <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" >
                    Microphone
                </label>
                <select name='microphone' onChange={onChange}
                    className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring" >
                    <option value={informations.microphone}>{informations.microphone === "oui" ? "Marche" :"Ne marche pas"} </option>
                    <option value="oui">Marche</option>
                    <option value="non" >Ne Marche pas</option> 
                </select>
                </div>
            </div>
            <div className="-mx-3 md:flex mb-2">
                <div className="md:w-1/2 px-3 mb-6 md:mb-0">
                <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2">
                    Tactile
                </label>
                <select name='tactile' onChange={onChange}
                    className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring" >
                    <option value="">tactile </option>
                    <option value="oui">Marche</option>
                    <option value="non" >Ne Marche pas</option> 
                </select>
                </div>
                <div className="md:w-1/2 px-3 mb-6 md:mb-0">
                <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2">
                    Ecran
                </label>
                <select name='screen' onChange={onChange}
                    className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring" >
                    <option value={informations.screen}>{informations.screen === "oui" ? "Non cassure" :"Cassure"}</option>
                    <option value="non">Cassure</option>
                    <option value="oui" >Non cassure</option> 
                </select>
                </div>
                <div className="md:w-1/2 px-3 mb-6 md:mb-0">
                <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" >
                    Numéro de serie
                </label>
                <input  value={serialNumber} onChange={(e)=>setSerialNumber(e.target.value)}
                        className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4" id="grid-city" type="text" />
                </div>
            </div>
                <div className="ml-14">
                <button   onClick={handleValider}
                    className='text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800'>
                    valider
                </button>
                </div>
            </div>
        }
    </div>
  )
}

export default ResultatEstim
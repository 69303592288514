import React from 'react'
import Cards from './Cards'
import Fade from 'react-reveal/Fade';
import Slider from './slide/Slide'
import Footer from './Footer';

  
const Services = () => {
  return (
    <>
          <div className="relative h-full w-full bg-[url('images/background-service.png')] bg-no-repeat bg-center bg-fixed bg-cover"  >
              <Cards />
              <div className='bg-white pb-3'>
                  <h1 className='text-center text-2xl mt-2  font-serif font-bold'><span className='text-[#0A3F65] font-bold'>"izicall, </span><span className='text-orange-400 font-bold'>izi fäy"</span> </h1>
                  <p className='text-center text-[#0A3F65] font-robo font-serif m-6  '>IZICALL est porté par le groupe Infolog Sénégal qui est un représentant agréé par Samsung. <br />
                      Un service après-vente comme la réparation et le dépannage est mis à votre disposition <br />
                      au niveau de notre boutique IZISHOP(Boutique Samsung Mermoz).
                      Et ce n'est pas tout! <br />
                      Vous bénéficiez aussi d'une garantie de 2 ans, d'une garantie Samsung Care et Cassure écran. <br />
                      Nos autres services comme la vente à crédit de téléphones neufs ou reconditionnés seront <span className='text-orange-400'>bientôt disponibles</span>
                  </p>
              </div>
              <div className="md:flex md:flex-row mt-10 justify-around" >
                <Fade left>
                    <div className="md:w-2/5 flex flex-col justify-center items-center">
                        <h2 className=" text-5xl text-[#0A3F65] mb-4 text-center md:self-start md:text-left  font-bold">Troc IZI</h2>
                            <p className="text-[#0A3F65] tracking-wide text-center md:self-start md:text-left font-robo">
                            Troquez votre Samsung avec IZICALL contre les nouvelles gammes Galaxy, originales et garantie Samsung care. 
                            IZICALL a pour mission d’offrir à ses clients la possibilité d’échanger leur téléphone  contre un vaste choix de téléphone de la marque Samsung allant de la gamme A à la gamme S.
                            Le règlement du troc se fait à crédit sur une durée de 3 mois, un paiement échelonné de manière souple et adapté à votre porte-monnaie. Nous vous proposons les dernières sorties Samsung Galaxy et donnons la priorité à vos préférences.
                        </p>        
                    </div>
                  </Fade>
                      <Fade right>
                      <div className='md:w-1/3 md:mt-40 '>
                          <Slider  />
                      </div>
                    </Fade>
            </div>
              <div className="md:flex md:flex-row mt-10 justify-around ms:m-5" > 
                  
                      <Fade right>
                      <div className='md:w-1/3 md:mt-16'>
                          <img src='https://th.bing.com/th/id/OIP.fblzEv6_OISQAkLF66Bk8AHaHa?pid=ImgDet&rs=1' alt='comming soon' className='rounded-full'  />
                      </div>
                  </Fade>
                  <Fade left>
                    <div className="md:w-3/5 flex flex-col justify-center items-center md:m-5">
                        <h2 className=" text-5xl text-[#0A3F65] mb-4 text-center md:self-start md:text-left  font-bold">Vente neuf IZI</h2>
                            <p className="text-[#0A3F65] tracking-wide text-center md:self-start md:text-left font-robo">
                            C'est l'heure d'acquérir un nouveau téléphone portable ! 
                            IZICALL vous propose des téléphones Samsung à crédit allant de la gamme A à la gamme S. Achetez et bénéficiez de facilités de paiement qui vous permettront d’acquérir votre nouveau téléphone payable pendant 6 mois. Ces gammes sont régulièrement renouvelées afin de vous permettre de disposer des dernières technologies et innovations. Vous allez bénéficier d’un service après-vente comme une garantie de 2 ans, une garantie Samsung Care etc.
                            Nous vous recommandons de vous rendre en agence afin d’être assisté par un de nos conseillers commerciaux dans le choix de l’appareil  et de la modalité de paiement qui répond le mieux à vos besoins.
                        </p> 
                    </div>
                  </Fade>
              </div>
              <div className="md:flex md:flex-row mt-10 justify-around ms:m-5 " >
                <Fade left>
                    <div className="md:w-3/5 flex flex-col justify-center items-center md:m-5">
                        <h2 className=" text-5xl text-[#0A3F65] mb-4 text-center md:self-start md:text-left  font-bold">2eme Main IZI</h2>
                            <p className="text-[#0A3F65] tracking-wide text-center md:self-start md:text-left font-robo">
                            IZICALL vous propose une large sélection de téléphone Samsung reconditionnés de qualité. Nos téléphones ont été soigneusement sélectionnés et testés pour garantir leur bon fonctionnement. Nous offrons une grande variété de modèles pour répondre à tous vos besoins et budgets. Tous nos téléphones sont vendus avec une garantie pour vous assurer de leur qualité et de leur fiabilité. N'hésitez pas à parcourir notre sélection de téléphone reconditionnés et à nous contacter si vous avez des questions ou des demandes spécifiques. Nous sommes là pour vous aider à trouver le téléphone parfait pour vous.
                        </p>
                        
                    </div>
                    </Fade>
                    
                  <Fade right>
                      <div className='md:w-1/3 md:mt-16'>   
                          <img src='https://th.bing.com/th/id/OIP.fblzEv6_OISQAkLF66Bk8AHaHa?pid=ImgDet&rs=1' alt='comming soon' className='rounded-full mb-3'  />
                      </div>
                    </Fade>
              </div>
              
              <Footer/>
          </div>   
    </>
  )
}

export default Services
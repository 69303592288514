import React, { useEffect, useRef } from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { clientService } from '../../../services/client/client'
import { phoneServices } from '../../../services/client/phone'
import { cryptoService } from '../../../services/crypto.service'
import Loading from '../../../utils/Loading'
import Modal from '../../../utils/Model'

const SuiviEstimation = () => {
    let navigate = useNavigate()
    const flag = useRef(false)

    const [estimation, setEstimation] = useState()
    const [isloading, setIsloading] = useState(true)
    const [pasDeEstimation, setPasDeEstimation] = useState(false) 
    
    useEffect(() => { 
        
        if (flag.current === false) { 
            phoneServices.checkEstimation()
                .then(response => {
                    if (response.status === 200) {
                    setIsloading(false)
                    response.json().then(data => {
                        data = cryptoService.decrypt(data.message, process.env.REACT_APP_SECRET_KEY)
                        setEstimation(data)
                    })
                    }
                    else if (response.status === 403) {
                    navigate("/auth/login") 
                    }
                    else {
                        setPasDeEstimation(true)
                        setIsloading(false)  
                    }
                })
        }
        return () => flag.current = true
    }, [])
    
    const [isloadingDetail, setIsloadingDetail] = useState(false)
    const [afficheDetail, setAfficheDetail] = useState(false)
    const [detail, setDetail] = useState()
    
    const afficheDetailEstimation = (model) => {
        setIsloadingDetail(true)
        clientService.getDetailEstimation(model)
            .then(response => {
                if (response.ok) {
                    setIsloadingDetail(false)
                    response.json().then(data => {
                        data = cryptoService.decrypt(data.message, process.env.REACT_APP_SECRET_KEY)
                        console.log(data)
                        setDetail(data)
                        setAfficheDetail(true)
                })
                
                }
                else {
                    setIsloadingDetail(false)
                }
        })
    }

    const [isloadingDelete, setIsloadingDelete] = useState(false) 
    const [erreurSupprimer, setErreurSupprimer] = useState(false)
    
    const supprimerEstimation = (id) => {
        var res = window.confirm("Êtes-vous sûr de vouloir supprimer l'estimation ?");
        if (res) {
            setIsloadingDelete(true)
            clientService.supprimerEstimation(id)
                .then(response => {
                    if (response.status === 200) {
                        setIsloadingDelete(false)
                        navigate("/client/compte/home")

                        setTimeout(() => {
                            window.location.reload() 
                        }, 1000); 
                    } else {
                        setIsloadingDelete(false)
                        setErreurSupprimer(true)
                }
            })
        }
    }

    if (isloading) {
        return <p>loading...</p>
    }
    if (pasDeEstimation) {
        return <p className='text-center font-bold text-xl mt-3'>Vous n'avez pas encors d'estimation</p>
    }
  return (
      <>
          <Modal
                isShowing={afficheDetail}
                hide={setAfficheDetail}
                title=""
            >
              <p>Model Choisi : {detail?.modelEch} </p>
              <p>Prix du model choisi : {detail?.prixCh} FCFA </p>
              <p>Prix de votre téléphone : {detail?.prixModel} FCFA </p>
          </Modal>
          <div className="md:ml-80 sm:ml-36 max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
              <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white ml-3">Information de l'estimation</h5> 
              {isloadingDetail && <Loading />}
              {isloadingDelete && <div className="ml-24 border-gray-300 h-20 w-20 animate-spin rounded-full border-8 border-t-red-600" />}
              {erreurSupprimer && 
                   <div className="mt-5 flex items-center justify-between p-5 leading-normal text-red-600 bg-red-100 rounded-lg" role="alert">
                        <p>Erreur de suppression</p>
                        <svg onClick={()=>setErreurSupprimer(false)} className="inline w-4 h-4 fill-current ml-2 hover:opacity-80 cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 464c-114.7 0-208-93.31-208-208S141.3 48 256 48s208 93.31 208 208S370.7 464 256 464zM359.5 133.7c-10.11-8.578-25.28-7.297-33.83 2.828L256 218.8L186.3 136.5C177.8 126.4 162.6 125.1 152.5 133.7C142.4 142.2 141.1 157.4 149.7 167.5L224.6 256l-74.88 88.5c-8.562 10.11-7.297 25.27 2.828 33.83C157 382.1 162.5 384 167.1 384c6.812 0 13.59-2.891 18.34-8.5L256 293.2l69.67 82.34C330.4 381.1 337.2 384 344 384c5.469 0 10.98-1.859 15.48-5.672c10.12-8.562 11.39-23.72 2.828-33.83L287.4 256l74.88-88.5C370.9 157.4 369.6 142.2 359.5 133.7z"/>
                        </svg>
                    </div>
              }
              <div className='flex flex-col'>
                  
                  {
                      estimation?.serialNumber !=" "?
                      <div className='flex flex-row justify-between m-3' >
                            <span>Numéro serie :  </span> <span>{estimation?.serialNumber}</span>
                    </div> 
                    :null
                  }
                  
                  <div className='flex flex-row justify-between m-3'>
                      <span>Camera arrière : </span> <span>{estimation?.rearCamera === "oui"? "Marche" : "Ne marche"} </span>
                  </div>
                  {
                      estimation?.frontCamera != "" ?
                      <div>
                           <span>Camera avant : {estimation?.frontCamera === "oui" ? "Marche" : "Ne marche"} </span>
                      </div>
                       : null  
                  }
                  <div className='flex flex-row justify-between m-3'>
                      <span>Moteur :</span> <span>{estimation?.motor === "oui"? "Marche" : "Ne marche"}</span>
                  </div>
                  <div className='flex flex-row justify-between m-3'>
                      <span>Port USB : </span> <span>{estimation?.sub === "oui"? "Marche" : "Ne marche"} </span>
                  </div>
                  <div className='flex flex-row justify-between m-3'>
                      <span>Estimation : </span> <span> {estimation?.estimate}</span>
                  </div>
             </div>
              <div className='flex flex-row justify-between'>
                    <button onClick={()=>{afficheDetailEstimation(estimation?.model)}}
                        className="m-3 text-center px-3 py-2 text-sm font-medium text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                        Afficher détail
                    </button>
                    <button onClick={()=> {supprimerEstimation(estimation?.id)}}
                        className="m-3 text-center px-3 py-2 text-sm font-medium text-white bg-red-700 rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800">
                        Supprimer estimation
                    </button>
              </div>
          </div> 
    </>
  )
}

export default SuiviEstimation
import CryptoJS from 'crypto-js';

function encrypt(data,key) {
    return CryptoJS.AES.encrypt(JSON.stringify(data), key);
  }
  
  function encryptCreateClient(data) {
    return CryptoJS.AES.encrypt(JSON.stringify(data), process.env.REACT_APP_SECRET_KEY_CREATE_USER);
  }
  
  function encryptLoginClient(data) {
    return CryptoJS.AES.encrypt(JSON.stringify(data), process.env.REACT_APP_SECRET_KEY);
  }

  function decrypt(ciphertext,key){
   
    const data = CryptoJS.AES.decrypt(ciphertext,key).toString(CryptoJS.enc.Utf8);
    return  JSON.parse(data);
    
  }
  export const cryptoService = {
    encrypt, decrypt,encryptCreateClient,encryptLoginClient
  }
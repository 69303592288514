import { useState } from "react";
import { Link } from "react-router-dom";
import logo from '../../images/logo.png'
export default function NavBar() {

    const [menuOpen, setMenuOpen] = useState(false);
  function toggleMenu() {
    setMenuOpen(!menuOpen);
  }
   //hover:bg-[#0A3F65] hover:text-white
    return (
        <>
      <nav className="flex flex-wrap items-center justify-between  bg-white">
        <img
          src={logo}
          className=" h-44 w-44 "
          alt="ACME"
          width="100"
        />
      <div className="flex md:hidden">
        <button id="hamburger" onClick={toggleMenu}>
          {menuOpen ? (
            <img
              className="toggle block"
              src="https://img.icons8.com/fluent-systems-regular/2x/close-window.png"
              width="40"
              height="40"
            />
          ) : (
            <img
              className="toggle block"
              src="https://img.icons8.com/fluent-systems-regular/2x/menu-squared-2.png"
              width="40"
              height="40"
            />
          )}
        </button>
      </div>
      <div
        className={`toggle ${menuOpen ? "" : "hidden"} w-full md:w-auto md:flex text-right text-bold mt-5 md:mt-0 border-t-2 border-blue-900 md:border-none`}
      >
        <Link
          to="/"
          className="m-2 md:mr-4 hover:bg-[#0A3F65] hover:rounded-xl hover:text-white  block md:inline-block text-blue-900  px-2 py-3 border-b-2 border-blue-900 md:border-none "
        >
          ACCUEIL
        </Link>
        <Link
          to="/services"
          className="m-2 md:mr-4 hover:bg-[#0A3F65] hover:rounded-xl hover:text-white block md:inline-block text-blue-900  px-3 py-3 border-b-2 border-blue-900 md:border-none"
        >
          NOS SERVICES
        </Link>
        <Link
          to="/contact"
          className="m-2 md:mr-4 hover:bg-[#0A3F65] hover:rounded-xl hover:text-white block md:inline-block text-blue-900  px-3 py-3 border-b-2 border-blue-900 md:border-none"
        >
          CONTACT
        </Link>
          <div className=" m-3 flex flex-row justify-between">
            <Link
                to="/auth/login"
                className="m-1 inline-block  w-full px-4 py-2 text-center text-white bg-[#0A3F65] rounded-md  hover:bg-[#0A3F65]"
            >
            connecter
            </Link>
            <Link
                to="/client/inscription"
                className="m-1 inline-block w-full px-2 py-2 text-center text-white bg-[#e88e56] rounded-md shadow hover:bg-[#e88e56]"
            >
                S'inscrire
            </Link>
        </div>
      </div>
      </nav>       
           
    </>
    );
}
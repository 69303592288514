import React from 'react'
import { Link } from 'react-router-dom'

const DetailProduit = () => {
  return (
    <div className="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3">
        <article className="overflow-hidden rounded-lg shadow-lg">
            <Link to="#">
                <img alt="Placeholder" className="block h-auto w-full" src="https://game-lord.com/uploads/files/images/news/2020/09/10/samsung-smartphone-transparent.jpg" />
            </Link>
            <header className="flex items-center justify-between leading-tight p-2 md:p-4">
                <h1 className="text-lg">
                    <p className="no-underline  text-black" >
                        RAM
                    </p>
                </h1>
                <p className="text-grey-darker text-sm">
                    3Go
                </p>
                
            </header>
            <header className="flex items-center justify-between leading-tight p-2 md:p-4">
                <h1 className="text-lg">
                    <p className="no-underline  text-black">
                        Capacité de stockage
                    </p>
                </h1>
                <p className="text-grey-darker text-sm">
                    32Go
                </p>
                
            </header>
            <header className="flex items-center justify-between leading-tight p-2 md:p-4">
                <h1 className="text-lg">
                    <p className="no-underline  text-black" >
                        Caméra 
                    </p>
                </h1>
                <p className="text-grey-darker text-sm">
                    12M pixel
                </p>
                
            </header>
            <Link to="#" className="bg-gradient-to-r from-blue-600 to-blue-800 rounded-full py-2 px-4 my-2 text-sm text-white hover:bg-orange-600 hover:from-orange-600 hover:to-orange-600 flex flex-row justify-center" >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
                </svg>
                Acheter
            </Link>
        </article>
    </div>
  )
}

export default DetailProduit
import React from 'react'
import { useContext } from 'react'
import { StepperContext } from './contexts/StepperContext'
import ReactFlagsSelect from 'react-flags-select';

const InformationPerso = () => {
    const { userData, setUserData,pays,setPays } = useContext(StepperContext)
    const onSelect = (code) => setPays(code);

    const handleChange = (e) => {
        const { name, value } = e.target
        setUserData({...userData, [name] : value})
    }
  return (
      <div className='flex flex-col'>
          <div className='w-full mx-2 flex-1'>
              <div className='font-bold h-6 mt-3 text-gray-500 text-xs leading-8 uppercase'>
                 Prénom <span className='text-red-600'>*</span>
              </div>
              <div className='bg-white my-2 p-1 flex border border-gray-200 rounded'>
                  <input
                      onChange={handleChange}
                      value={userData.firstName}
                      name="firstName"
                      placeholder='Votre prénom'
                      className='p-1 px-2 appearance-none outline-none w-full text-gray-800'
                      type="text" />
              </div> 
          </div>
          <div className='w-full mx-2 flex-1'>
              <div className='font-bold h-6 mt-3 text-gray-500 text-xs leading-8 uppercase'>
                 Nom <span className='text-red-600'>*</span>
              </div>
              <div className='bg-white my-2 p-1 flex border border-gray-200 rounded'>
                  <input
                      onChange={handleChange}
                      value={userData.lastName}
                      name="lastName"
                      placeholder='Votre nom'
                      className='p-1 px-2 appearance-none outline-none w-full text-gray-800'
                      type="text" />
              </div> 
          </div>
          <div className='w-full mx-2 flex-1'>
              <div className='font-bold h-6 mt-3 text-gray-500 text-xs leading-8 uppercase'>
                 Pays <span className='text-red-600'>*</span>
              </div>
              <div className='bg-white my-2 p-1 flex border border-gray-200 rounded'>
                  <ReactFlagsSelect 
                    className="block  w-full px-2 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                    selected={pays}
                    onSelect={onSelect}
                    countries={["CI", "ML", "SN"]} 
                    placeholder="Choisir pays" 
                    optionsSize={13}      
                    />
              </div> 
          </div>

          <div className='w-full mx-2 flex-1'>
              <div className='font-bold h-6 mt-3 text-gray-500 text-xs leading-8 uppercase'>
                 Profession <span className='text-red-600'>*</span>
              </div>
              <div className='bg-white my-2 p-1 flex border border-gray-200 rounded'>
                  <input
                      onChange={handleChange}
                      value={userData.profession}
                      name="profession"
                      placeholder='Votre  profession'
                      className='p-1 px-2 appearance-none outline-none w-full text-gray-800'
                      type="text" />
              </div>
          </div>
    </div>
  )
}

export default InformationPerso